export const UpdateSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3333 10L10 6.66669M10 6.66669L6.66667 10M10 6.66669V14.3334C10 15.4923 10 16.0717 10.4588 16.7205C10.7636 17.1516 11.6412 17.6836 12.1644 17.7545C12.9518 17.8612 13.2508 17.7052 13.8488 17.3932C16.5139 16.003 18.3333 13.214 18.3333 10C18.3333 5.39765 14.6024 1.66669 10 1.66669C5.39763 1.66669 1.66667 5.39765 1.66667 10C1.66667 13.0845 3.3425 15.7776 5.83334 17.2185"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
