export const ExternalLinkSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="arrow-up-right">
      <path
        id="Icon"
        d="M5.83337 14.1667L14.1667 5.83333M14.1667 5.83333H5.83337M14.1667 5.83333V14.1667"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
