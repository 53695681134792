import { DataSource, ManifestType } from "@/generated/graphql";

export const pluralize = (count: number, singular: string, plural: string) => {
  if (count === 0) return singular;

  return count === 1 ? singular : plural;
};

export const getInitials = function (fullName: string) {
  if (!fullName) return "";

  const names = fullName.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const connectGithubRepository = () => {
  window.location.href =
    import.meta.env.VITE_GITHUB_APP_URL + "/installations/new";
};

export const getManifestTypeImg = (type: ManifestType) => {
  switch (type) {
    case ManifestType.Npm:
      return "/img/npm.svg";
    case ManifestType.Yarn:
      return "/img/yarn.svg";
    case ManifestType.Go:
      return "/img/go.svg";
    case ManifestType.Cargo:
      return "/img/cargo.svg";
    case ManifestType.Composer:
      return "/img/composer.svg";
    case ManifestType.Gem:
      return "/img/gem.svg";
    case ManifestType.Mix:
      return "/img/mix.svg";
    case ManifestType.Maven:
      return "/img/maven.svg";
    case ManifestType.Gradle:
      return "/img/gradle.svg";
    case ManifestType.Pip:
      return "/img/pip.svg";
    case ManifestType.Docker:
      return "/img/docker.svg";
    default:
      return "";
  }
};

export const getDataSourceImg = (type?: DataSource) => {
  switch (type) {
    case DataSource.Npm:
      return "/img/npm.svg";
    default:
      return "";
  }
};
