import * as Sentry from "@sentry/browser";
import { Session, User, createClient } from "@supabase/supabase-js";
import { Crisp } from "crisp-sdk-web";
import { usePostHog } from "posthog-js/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const supabaseProjectId = import.meta.env.VITE_SUPABASE_PROJECT_ID;
const supabaseUrl = `https://${supabaseProjectId}.supabase.co`;
const publicAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, publicAnonKey);

type AuthContextValue = {
  isAuthenticated: boolean | null;
  logout: () => void;
  user: User | null;
  session: Session | null;
};

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: null,
  logout: () => null,
  user: null,
  session: null,
});

export function useAuth() {
  return useContext(AuthContext);
}

type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] =
    useState<AuthContextValue["isAuthenticated"]>(null);
  const [user, setUser] = useState<AuthContextValue["user"]>(null);
  const [session, setSession] = useState<AuthContextValue["session"]>(null);
  const posthog = usePostHog();
  const i = window.localStorage.getItem("i");

  useEffect(() => {
    if (i) {
      setIsAuthenticated(true);
      return;
    }

    supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setIsAuthenticated(true);
        setUser(session?.user ?? null);
        setSession(session ?? null);
      } else if (event !== "INITIAL_SESSION" && event !== "TOKEN_REFRESHED") {
        setIsAuthenticated(false);
        setUser(null);
        setSession(null);
      }
    });
  }, []);

  useEffect(() => {
    if (!user) return;

    Crisp.user.setEmail(user?.email ?? "");
    Crisp.session.setData({
      user_id: user?.id,
      email: user?.email,
    });

    Sentry.setUser({ email: user?.email, id: user?.id });

    posthog.identify(user?.id, {
      email: user?.email,
    });
  }, [user, posthog]);

  useEffect(() => {
    if (user) return;

    if (i) {
      setIsAuthenticated(true);
      return;
    }

    supabase.auth.getSession().then(({ data, error }) => {
      if (error) {
        console.error(error);
        return;
      }

      if (data.session) {
        supabase.auth.getUser().then(({ data: { user }, error }) => {
          if (error) {
            setIsAuthenticated(false);
            setUser(null);
            supabase.auth.signOut();
          } else {
            setIsAuthenticated(true);
            setSession(data.session);
            setUser(user);
          }
        });
      } else {
        setIsAuthenticated(false);
      }
    });
  }, [user]);

  // Function to simulate a logout
  const logout = useCallback(async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
    setUser(null);
    setSession(null);
  }, []);

  // Provide the authentication state and functions to the context
  const authContextValue: AuthContextValue = useMemo(
    () => ({
      isAuthenticated,
      logout,
      user,
      session,
    }),
    [isAuthenticated, logout, user, session]
  );

  if (isAuthenticated === null) {
    return null;
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {" "}
      {children}
    </AuthContext.Provider>
  );
}
