import { RouterProvider } from "react-router-dom";

import { useAuth } from "@/providers/AuthProvider";

import { loggedInRouter } from "./LoggedInRouter";
import { loggedOutRouter } from "./LoggedOutRouter";

export const MainRouter = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <RouterProvider
        router={isAuthenticated ? loggedInRouter : loggedOutRouter}
      />
    </>
  );
};
