import { AlertOctagonSVG } from "./svg/AlertOctagon";
import { AlertTriangleSVG } from "./svg/AlertTriangle";
import { AlignLeftSVG } from "./svg/AlignLeft";
import { AnnouncementSVG } from "./svg/Announcement";
import { ArrowRightSVG } from "./svg/ArrowRight";
import { BarChartSVG } from "./svg/BarChart";
import { BitbucketSVG } from "./svg/Bitbucket";
import { BracketsSVG } from "./svg/Brackets";
import { CalendarSVG } from "./svg/Calendar";
import { CheckSVG } from "./svg/Check";
import { ChevronRightSVG } from "./svg/ChevronRight";
import { CloseSVG } from "./svg/Close";
import { CoinsHandSVG } from "./svg/CoinsHand";
import { DocsSVG } from "./svg/Docs";
import { DollarSVG } from "./svg/Dollar";
import { DoubleCheckSVG } from "./svg/DoubleCheck";
import { EmailSVG } from "./svg/Email";
import { ExternalLinkSVG } from "./svg/ExternalLink";
import { EyeSVG } from "./svg/Eye";
import { FileSVG } from "./svg/File";
import { FileCodeSVG } from "./svg/FileCode";
import { FileShieldSVG } from "./svg/FileShield";
import { FlagSVG } from "./svg/Flag";
import { GithubSVG } from "./svg/Github";
import { GitlabSVG } from "./svg/Gitlab";
import { GlobeSVG } from "./svg/Globe";
import { HashSVG } from "./svg/Hash";
import { HomeSVG } from "./svg/Home";
import { LogoutSVG } from "./svg/Logout";
import { MenuSVG } from "./svg/Menu";
import { MessageSVG } from "./svg/Message";
import { MoreSVG } from "./svg/More";
import { PackageSVG } from "./svg/Package";
import { PhoneSVG } from "./svg/Phone";
import { PlusSVG } from "./svg/Plus";
import { RepositoriesSVG } from "./svg/Repositories";
import { SearchSVG } from "./svg/Search";
import { SettingsSVG } from "./svg/Settings";
import { SignalSVG } from "./svg/Signal";
import { StarsSVG } from "./svg/Stars";
import { TerminalSVG } from "./svg/Terminal";
import { TrashSVG } from "./svg/Trash";
import { UpdateSVG } from "./svg/Update";
import { UsersSVG } from "./svg/Users";
import { ZapFastSVG } from "./svg/ZapFast";

export const Icon = {
  AlertOctagon: AlertOctagonSVG,
  AlertTriangle: AlertTriangleSVG,
  AlignLeft: AlignLeftSVG,
  Announcement: AnnouncementSVG,
  ArrowRight: ArrowRightSVG,
  BarChart: BarChartSVG,
  Bitbucket: BitbucketSVG,
  Brackets: BracketsSVG,
  Calendar: CalendarSVG,
  Check: CheckSVG,
  ChevronRight: ChevronRightSVG,
  Close: CloseSVG,
  CoinsHand: CoinsHandSVG,
  Docs: DocsSVG,
  Dollar: DollarSVG,
  DoubleCheck: DoubleCheckSVG,
  Email: EmailSVG,
  ExternalLink: ExternalLinkSVG,
  Eye: EyeSVG,
  File: FileSVG,
  FileCode: FileCodeSVG,
  FileShield: FileShieldSVG,
  Flag: FlagSVG,
  Github: GithubSVG,
  Gitlab: GitlabSVG,
  Globe: GlobeSVG,
  Hash: HashSVG,
  Home: HomeSVG,
  Logout: LogoutSVG,
  Menu: MenuSVG,
  Message: MessageSVG,
  More: MoreSVG,
  Package: PackageSVG,
  Phone: PhoneSVG,
  Plus: PlusSVG,
  Repositories: RepositoriesSVG,
  Search: SearchSVG,
  Settings: SettingsSVG,
  Signal: SignalSVG,
  Stars: StarsSVG,
  Terminal: TerminalSVG,
  Trash: TrashSVG,
  Update: UpdateSVG,
  Users: UsersSVG,
  ZapFast: ZapFastSVG,
};
