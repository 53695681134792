import { createContext, useContext, useMemo, useState } from "react";

import {
  CreateWorkspace,
  CreateWorkspaceProps,
} from "@/modals/CreateWorkspace/CreateWorkspace";
import {
  InviteMembers,
  InviteMembersProps,
} from "@/modals/InviteMembers/InviteMembers";
import {
  PreviewUpdates,
  PreviewUpdatesProps,
} from "@/modals/PreviewUpdates/PreviewUpdates";

type DialogContextType = {
  dialog?: Dialog;
  openDialog: (dialog: Dialog, props?: DialogProps) => void;
  closeDialog: () => void;
};

const DialogContext = createContext<DialogContextType>({
  openDialog: () => null,
  closeDialog: () => null,
});

export enum Dialog {
  PreviewUpdates = "PreviewUpdates",
  CreateWorkspace = "CreateWorkspace",
  InviteMembers = "InviteMembers",
}

// This should be a union of all the props for the dialogs
type DialogProps =
  | PreviewUpdatesProps
  | CreateWorkspaceProps
  | InviteMembersProps;

type DialogConfig = {
  id: Dialog;
  component: React.FC<DialogProps>;
};

const dialogs: DialogConfig[] = [
  {
    id: Dialog.PreviewUpdates,
    component: PreviewUpdates,
  },
  {
    id: Dialog.CreateWorkspace,
    component: CreateWorkspace,
  },
  {
    id: Dialog.InviteMembers,
    component: InviteMembers,
  },
];

export const useDialogContext = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialogContext must be used within a DialogProvider");
  }
  return context;
};

type DialogProviderProps = {
  children: React.ReactNode;
};

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialog, setDialog] = useState<Dialog>();
  const [props, setProps] = useState<DialogProps>({});

  const openDialog = (dialog: Dialog, options?: DialogProps) => {
    setDialog(dialog);
    setProps(options ?? {});
  };

  const closeDialog = () => {
    setDialog(undefined);
    setProps({});
  };

  const context = useMemo(
    () => ({
      dialog,
      openDialog,
      closeDialog,
    }),
    [dialog]
  );

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      closeDialog();
    }
  };

  const Component = dialogs.find((d) => d.id === dialog)?.component;

  return (
    <DialogContext.Provider value={context}>
      {children}
      {dialog && Component && (
        <Component open onOpenChange={handleOpenChange} {...props} />
      )}
    </DialogContext.Provider>
  );
};
