import * as Tabs from "@radix-ui/react-tabs";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import {
  tabsContentStyles,
  tabsListStyles,
  tabsTriggerStyles,
} from "@/components/Tabs/Tabs.css";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import { usePackageQuery } from "@/generated/graphql";
import { AppLayout } from "@/layouts/AppLayout/AppLayout";

import { Overview } from "./Overview/Overview";
import { tabsRootStylesRepos } from "./Package.css";
import { UsedIn } from "./UsedIn/UsedIn";
import { Versions } from "./Versions/Versions";

export const Package = () => {
  const { id } = useParams<{ id: string }>();

  const [{ data }] = usePackageQuery({
    pause: !id,
    variables: {
      id: id ?? "",
    },
  });

  if (!data?.package) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{data?.package.name} - DepsHub</title>
      </Helmet>
      <AppLayout.Header
        title={data?.package.name}
        description={data?.package.description}
      />
      <Tabs.Root className={tabsRootStylesRepos} defaultValue="overview">
        <Tabs.List className={tabsListStyles}>
          <Tabs.Trigger className={tabsTriggerStyles} value="overview">
            Overview
          </Tabs.Trigger>
          <Tabs.Trigger className={tabsTriggerStyles} value="versions">
            Versions{" "}
            <VersionPill version={String(data?.package?.versions?.length)} />
          </Tabs.Trigger>
          <Tabs.Trigger className={tabsTriggerStyles} value="usedIn">
            Used in
            <VersionPill version={String(data?.package?.usedIn.length)} />
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content className={tabsContentStyles} value="overview">
          <Overview pkg={data?.package} />
        </Tabs.Content>
        <Tabs.Content className={tabsContentStyles} value="versions">
          <Versions versions={data?.package.versions} />
        </Tabs.Content>
        <Tabs.Content className={tabsContentStyles} value="usedIn">
          <UsedIn usedIn={data?.package.usedIn} />
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};
