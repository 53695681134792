import { assignInlineVars } from "@vanilla-extract/dynamic";

import { marginBottomVar, marginTopVar, wrapperStyles } from "./Separator.css";

// TODO should be replaced with https://www.radix-ui.com/docs/primitives/components/separator
type SeparatorProps = {
  marginTop?: string;
  marginButtom?: string;
};

export const Separator = ({
  marginTop = "24px",
  marginButtom = "24px",
}: SeparatorProps) => {
  return (
    <div
      className={wrapperStyles}
      style={assignInlineVars({
        [marginTopVar]: marginTop,
        [marginBottomVar]: marginButtom,
      })}
    />
  );
};
