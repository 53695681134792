import {
  Line,
  LineChart as LineChartRecharts,
  ResponsiveContainer,
} from "recharts";

import { vars } from "@/styles/theme.css";

const dataKey = "value";

export type LineChartData = {
  name: string;
  [dataKey]: number;
}[];

export type LineChartProps = {
  data: LineChartData;

  // The goal value determines the gradient color direction
  goal: "min" | "max";
};

export const LineChart = ({ data, goal = "max" }: LineChartProps) => {
  const gradientId = Math.random().toString();

  // Do not render if there is no data
  if (data.length <= 1) return null;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChartRecharts data={data}>
        <defs>
          {/* Define a linear gradient */}
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="50%"
              stopColor={
                goal === "max" ? vars.color.success9 : vars.color.error9
              }
            />
            <stop
              offset="100%"
              stopColor={
                goal === "max" ? vars.color.error9 : vars.color.success9
              }
            />
          </linearGradient>
        </defs>
        <Line
          type="monotone"
          dataKey={dataKey}
          strokeWidth={2}
          dot={false}
          // Use the gradient as the stroke
          stroke={`url(#${gradientId})`}
        />
      </LineChartRecharts>
    </ResponsiveContainer>
  );
};
