import { Outlet } from "react-router-dom";

import { Sidebar } from "@/components/Sidebar/Sidebar";
import { useRootQueryQuery } from "@/generated/graphql";

import { contentStyles, wrapperStyles } from "./AppLayout.css";
import { Header } from "./Header/Header";

export const AppLayout = () => {
  const [{ data }] = useRootQueryQuery();

  return (
    <div className={wrapperStyles}>
      <Sidebar
        user={data?.user}
        workspaces={data?.workspaces}
        workspace={data?.workspace}
        notifications={data?.notifications}
      />
      <div className={contentStyles}>
        <Outlet />
      </div>
    </div>
  );
};

AppLayout.Header = Header;
