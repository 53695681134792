import { Outlet } from "react-router-dom";

import { headerStyles, logoStyles, wrapperStyles } from "./ExternalLayout.css";

export const ExternalLayout = () => {
  return (
    <div className={wrapperStyles}>
      <div className={headerStyles}>
        <a href="/">
          <img className={logoStyles} src="/logo.svg" />
        </a>
      </div>
      <Outlet />
    </div>
  );
};
