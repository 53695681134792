import { useEffect, useState } from "react";

import { Button } from "@/components/Button/Button";
import { Input } from "@/components/Input/Input";

import {
  useRootQueryQuery,
  useUpdateWorkspaceSettingsMutation,
} from "../../../../generated/graphql";
import { wrapperStyles } from "./WorkspaceName.css";

type WorkspaceNameProps = {
  onNext: () => void;
};

export const WorkspaceName = ({ onNext }: WorkspaceNameProps) => {
  const [{ data }] = useRootQueryQuery();
  const workspaceName = data?.workspace?.name;
  const [, updateWorkspaceSettingsMutation] =
    useUpdateWorkspaceSettingsMutation();

  const [name, setName] = useState(workspaceName || "");

  useEffect(() => {
    setName(workspaceName || "");
  }, [workspaceName]);

  const handleContinue = async () => {
    await updateWorkspaceSettingsMutation({
      settings: {
        name,
      },
    });
    onNext();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <div className={wrapperStyles}>
      <Input
        placeholder="Workspace name"
        defaultValue={workspaceName}
        onChange={handleNameChange}
      />
      <Button onClick={handleContinue} disabled={!name}>
        Continue
      </Button>
    </div>
  );
};
