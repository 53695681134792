import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";

import { ScoreRadialBar } from "@/components/ScoreRadialBar/ScoreRadialBar";
import {
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import { PackagesQuery } from "@/generated/graphql";
import { getDataSourceImg } from "@/utils";

import { orgImgStyles, repoNameStyles } from "./Packages.css";
import { tdOrganizationStyles } from "./Packages.css";

export type Package = PackagesQuery["packages"][0];

const columnHelper = createColumnHelper<Package>();

const columns = [
  columnHelper.accessor("name", {
    header: () => <>Package</>,
    cell: ({ getValue, row }) => (
      <span className={repoNameStyles}>
        <img
          src={getDataSourceImg(row.original.dataSource)}
          alt={row.original.dataSource}
          className={orgImgStyles}
        />
        {getValue()}
      </span>
    ),
    maxSize: 100,
    size: 100,
    enableGrouping: true,
  }),
  columnHelper.accessor("score.total", {
    header: () => <>Score</>,
    cell: ({ getValue }) => (
      <span className={repoNameStyles}>
        <ScoreRadialBar value={getValue()} />
      </span>
    ),
    maxSize: 100,
    size: 100,
    enableGrouping: true,
  }),
];

type TableProps = {
  data?: PackagesQuery;
  onRowClick?: (row: Package, e: React.MouseEvent<HTMLTableRowElement>) => void;
};

export const Table = ({ data, onRowClick = () => null }: TableProps) => {
  const table = useReactTable({
    data: data?.packages || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false,
  });

  return (
    <table className={wrapperStyles}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className={thStyles}
                key={header.id}
                style={{
                  width: header.column.columnDef.size,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr
              key={row.id}
              className={trStyles}
              onClick={(e) => {
                onRowClick(row.original, e);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={tdOrganizationStyles}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
