import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import { StatusBar } from "@/components/StatusBar/StatusBar";
import {
  tdStyles,
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import { RepositoryPreview, RepositoryState } from "@/generated/graphql";

import { nameStyles } from "./UsedIn.css";

const columnHelper = createColumnHelper<RepositoryPreview>();

const columns = [
  columnHelper.accessor("name", {
    header: () => <>Repository</>,
    cell: ({ getValue }) => <span className={nameStyles}>{getValue()}</span>,
    maxSize: 100,
    size: 100,
  }),
  columnHelper.accessor("state", {
    header: () => <>State</>,
    cell: ({ row }) => {
      const isRepositoryProcessing =
        row.original.state === RepositoryState.Processing;

      return (
        <span className={nameStyles}>
          <StatusBar
            updated={row.original.status.updated}
            outdated={row.original.status.outdated}
            critical={row.original.status.critical}
            security={row.original.status.security}
            isLoading={isRepositoryProcessing}
          />
        </span>
      );
    },
    maxSize: 100,
    size: 100,
  }),
];

type UsedInProps = {
  usedIn: RepositoryPreview[];
};

export const UsedIn = ({ usedIn }: UsedInProps) => {
  const navigate = useNavigate();

  const table = useReactTable({
    data: usedIn ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false,
  });

  const handleRowClick = (repo: RepositoryPreview, e: React.MouseEvent) => {
    const url = `/repositories/${repo.id}`;

    // TODO Handle middle mouse click
    // https://github.com/TanStack/table/discussions/2336#discussioncomment-2948209
    //
    // Handle Cmd + Click
    if (e.metaKey || e.ctrlKey) {
      return window.open(url, "_blank");
    }

    navigate(url);
  };

  return (
    <table className={wrapperStyles}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className={thStyles}
                key={header.id}
                style={{
                  width: header.column.columnDef.size,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr
              key={row.id}
              className={trStyles}
              onClick={(e) => handleRowClick(row.original, e)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={tdStyles}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
