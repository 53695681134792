import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";

import {
  tdStyles,
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import { PackageVersion } from "@/generated/graphql";

import { nameStyles } from "./Versions.css";

const columnHelper = createColumnHelper<PackageVersion>();

const columns = [
  columnHelper.accessor("rawVersion", {
    header: () => <>Version</>,
    cell: ({ getValue }) => (
      <span className={nameStyles}>
        <VersionPill version={getValue()} />
      </span>
    ),
    maxSize: 100,
    size: 100,
    enableGrouping: true,
  }),
  columnHelper.accessor("releasedAt", {
    header: () => <>Released</>,
    cell: ({ getValue }) => {
      const value = getValue();

      if (!value) return null;

      return (
        <span className={nameStyles}>
          {format(new Date(getValue()), "yyyy-MM-dd")}
        </span>
      );
    },
    maxSize: 100,
    size: 100,
    enableGrouping: true,
  }),
];

type VerionsProps = {
  versions: PackageVersion[];
};

export const Versions = ({ versions }: VerionsProps) => {
  const table = useReactTable({
    data: versions ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false,
  });

  return (
    <table className={wrapperStyles}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className={thStyles}
                key={header.id}
                style={{
                  width: header.column.columnDef.size,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id} className={trStyles}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={tdStyles}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
