import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useParams } from "react-router-dom";

import { Avatar } from "@/components/Avatar/Avatar";
import { supabase } from "@/providers/AuthProvider";
import { useInvitationData } from "@/publicApi";

import {
  descriptionStyles,
  invitationCardStyles,
  nameStyles,
  pageWrapperStyles,
  teamNameStyles,
  titleStyles,
} from "./Invitation.css";

export const Invitation = () => {
  const { code } = useParams<{ code: string }>();
  const invitationData = useInvitationData(code ?? "");

  return (
    <div className={pageWrapperStyles}>
      <div className={invitationCardStyles}>
        <div className={titleStyles}>
          <b className={nameStyles}>
            <Avatar
              src={invitationData?.inviter?.profileImg ?? ""}
              fallback={invitationData?.inviter?.name ?? ""}
            />
            {invitationData?.inviter?.name ?? ""}
          </b>
          <span> invited you to join</span>
        </div>
        <div className={teamNameStyles}>{invitationData?.workspaceName}</div>
        <div className={descriptionStyles}>
          Use any of the methods below to join the team.
        </div>
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={["github"]}
          redirectTo="/"
        />
      </div>
    </div>
  );
};
