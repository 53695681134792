import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar } from "@/components/Avatar/Avatar";
import { Dialog, useDialogContext } from "@/components/Dialog/DialogContext";
import {
  Dropdown,
  dropdownMenuContentStyles,
  dropdownMenuItemStyles,
} from "@/components/Dropdown/Dropdown";
import {
  dropdownMenuItemIconStyles,
  dropdownMenuRightSlotStyles,
  dropdownMenuSeparatorStyles,
  dropdownMenuSubContentStyles,
  dropdownMenuSubTriggerStyles,
} from "@/components/Dropdown/Dropdown.css";
import { Icon } from "@/components/Icon/Icon";
import { useAuth } from "@/providers/AuthProvider";
import { getInitials } from "@/utils";

import {
  Workspace,
  useSelectWorkspaceMutation,
} from "../../../../generated/graphql";
import {
  nameStyles,
  profileStyles,
  userInfoStyles,
  workspaceNameStyles,
  wrapperStyles,
} from "./Profile.css";

type ProfileProps = {
  profileImg?: string;
  name: string;
  email: string;
  workspace?: Workspace;
  workspaces?: Workspace[];
};

export const Profile = ({
  profileImg = "",
  name = "",
  email = "",
  workspaces = [],
  workspace,
}: ProfileProps) => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const { openDialog } = useDialogContext();
  const [, selectWorkspaceMutation] = useSelectWorkspaceMutation();
  // We're using controlled DropdownMenu here to fix an issue
  // when the body element has pointer-events: none after the dialog is closed
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (!email) return null;

  const handleWorkspaceCreate = () => {
    setIsDropdownOpen(false);
    openDialog(Dialog.CreateWorkspace);
  };

  const navigateToSettings = () => {
    setIsDropdownOpen(false);
    navigate("/settings");
  };

  const navigateToInvite = () => {
    setIsDropdownOpen(false);
    openDialog(Dialog.InviteMembers);
  };

  const handleWorkspaceSelect = (id: string) => {
    selectWorkspaceMutation({
      id,
    });

    window.location.reload();
  };

  const dropdownContent = (
    <DropdownMenu.Content className={dropdownMenuContentStyles} sideOffset={5}>
      <DropdownMenu.Sub>
        <DropdownMenu.Item className={dropdownMenuItemStyles} disabled>
          {user?.email}
        </DropdownMenu.Item>
        <DropdownMenu.Separator className={dropdownMenuSeparatorStyles} />
        <DropdownMenu.SubTrigger className={dropdownMenuSubTriggerStyles}>
          <Icon.Users className={dropdownMenuItemIconStyles} />
          Switch workspace
          <div className={dropdownMenuRightSlotStyles}>
            <Icon.ChevronRight />
          </div>
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Item
          className={dropdownMenuItemStyles}
          onClick={navigateToInvite}
        >
          <Icon.Email className={dropdownMenuItemIconStyles} />
          Invite a teammate
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={dropdownMenuItemStyles}
          onClick={navigateToSettings}
        >
          <Icon.Settings className={dropdownMenuItemIconStyles} />
          Workspace settings
        </DropdownMenu.Item>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            className={dropdownMenuSubContentStyles}
            sideOffset={2}
            alignOffset={-5}
          >
            <DropdownMenu.RadioGroup
              value={workspace?.id}
              onValueChange={handleWorkspaceSelect}
            >
              {workspaces.map((workspace) => (
                <DropdownMenu.RadioItem
                  key={workspace.id}
                  className={dropdownMenuItemStyles}
                  value={workspace.id}
                >
                  <DropdownMenu.ItemIndicator
                    className={dropdownMenuItemIconStyles}
                  >
                    <Icon.Check />
                  </DropdownMenu.ItemIndicator>
                  {workspace.name}
                </DropdownMenu.RadioItem>
              ))}

              <DropdownMenu.Separator className={dropdownMenuSeparatorStyles} />
              <DropdownMenu.Item
                className={dropdownMenuItemStyles}
                onClick={handleWorkspaceCreate}
              >
                <Icon.Plus className={dropdownMenuItemIconStyles} />
                Create a new workspace
              </DropdownMenu.Item>
            </DropdownMenu.RadioGroup>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>

      <DropdownMenu.Separator className={dropdownMenuSeparatorStyles} />
      <DropdownMenu.Item className={dropdownMenuItemStyles} onClick={logout}>
        <Icon.Logout className={dropdownMenuItemIconStyles} />
        Log out
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  );

  return (
    <Dropdown
      content={dropdownContent}
      open={isDropdownOpen}
      onOpenChange={setIsDropdownOpen}
    >
      <div className={wrapperStyles}>
        <div className={profileStyles}>
          <Avatar src={profileImg} fallback={getInitials(name)} />
        </div>
        <div className={userInfoStyles}>
          <div className={nameStyles}>{name}</div>
          <div className={workspaceNameStyles}>{workspace?.name}</div>
        </div>
      </div>
    </Dropdown>
  );
};
