import { Link } from "react-router-dom";

import { ScoreRadialBar } from "@/components/ScoreRadialBar/ScoreRadialBar";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import { DataSource } from "@/generated/graphql";
import { getDataSourceImg } from "@/utils";

import { HoverCard } from "../HoverCard";
import {
  descriptionStyles,
  imgStyles,
  leftStyles,
  linkStyles,
  mainInfoStyles,
} from "./Package.css";

type PackageHoverCardProps = {
  id: string;
  name: string;
  description: string;
  score: number;
  dataSource?: DataSource;
};

export const PackageHoverCard = ({
  id,
  name,
  score,
  description,
  dataSource,
}: PackageHoverCardProps) => {
  const content = (
    <>
      <div className={mainInfoStyles}>
        <div className={leftStyles}>
          <img
            src={getDataSourceImg(dataSource)}
            alt={name}
            className={imgStyles}
          />
          <b>{name}</b>
        </div>
        <ScoreRadialBar value={score} />
      </div>
      <div className={descriptionStyles}>{description}</div>
    </>
  );

  return (
    <HoverCard content={content}>
      <Link to={"/packages/" + id} className={linkStyles}>
        <VersionPill version={name} />
      </Link>
    </HoverCard>
  );
};
