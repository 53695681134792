import { useEffect, useState } from "react";

const publicApiUrl = import.meta.env.VITE_API_URL + "/public";

type InvitationData = {
  id: string;
  code?: string;
  workspaceName?: string;
  inviter?: {
    name: string;
    profileImg: string;
    email: string;
  };
};

export const useInvitationData = (code: string) => {
  const [invitationData, setInvitationData] = useState<InvitationData | null>(
    null
  );

  useEffect(() => {
    if (!code) {
      return;
    }

    const fetchInvitationData = async () => {
      const result = await fetch(publicApiUrl + "/invitation", {
        method: "POST",
        body: JSON.stringify({
          code,
        }),
      });
      const data = (await result.json()) as InvitationData;

      return data;
    };

    fetchInvitationData().then((data) => setInvitationData(data));
  }, [code]);

  return invitationData;
};
