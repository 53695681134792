import * as Sentry from "@sentry/react";

export const sentryInit = () => {
  if (!import.meta.env.PROD) return;

  Sentry.init({
    dsn: "https://744e159afb4d1619a89a77063d6e7834@o4506588425879552.ingest.sentry.io/4506588427976704",
    integrations: [],
  });
};
