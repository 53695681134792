import { badgeIndicatorStyles, badgeStyles } from "./NotificationBadge.css";

type NotificationBadgeProps = {
  count?: number;
};

// TODO Merge this component with VersionPill
export const NotificationBadge = ({ count }: NotificationBadgeProps) => {
  if (!count) return null;

  return (
    <div className={badgeStyles}>
      <div className={badgeIndicatorStyles} />
      {count}
    </div>
  );
};
