export const DoubleCheckSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284"
    />
  </svg>
);
