import { devtoolsExchange } from "@urql/devtools";
import { authExchange } from "@urql/exchange-auth";
import { Data, cacheExchange } from "@urql/exchange-graphcache";
import { createClient as createWSClient } from "graphql-ws";
import { createClient, fetchExchange, subscriptionExchange } from "urql";

import { Vulnerability } from "../generated/graphql";
import schema from "../generated/graphql.schema.json";
import { supabaseProjectId } from "./providers/AuthProvider";

export const cache = cacheExchange({
  schema: schema,
  updates: {
    Mutation: {
      createWorkspace: (result, _args, cache) => {
        const workspaces = cache.resolve("Query", "workspaces");
        if (Array.isArray(workspaces)) {
          workspaces.push(result.createWorkspace as Data);
          cache.link("Query", "workspaces", workspaces as Data[]);
        }
      },
    },
  },
  keys: {
    UpgradeStatus: () => null,
    DependencyUpgrade: () => null,
    HistoryPoint: () => null,
    Stats: () => null,
    Analytics: () => null,
    HistoricalStats: () => null,
    RepositorySettings: () => null,
    PullRequest: () => null,
    Vulnerability: (data) => {
      const vuln = data as Vulnerability;
      if (!vuln) {
        return null;
      }

      const id = vuln.id + vuln.name + vuln.occurrence.manifestPackageId;
      return id;
    },
    VulnerabilityReference: () => null,
    VulnerabilityOccurence: () => null,
    Severity: () => null,
    LicenseInfo: () => null,
    LicensePackage: () => null,
    Score: () => null,
  },
});

const getToken = () => {
  const storageKey = `sb-${supabaseProjectId}-auth-token`;
  const sessionDataString = localStorage.getItem(storageKey);
  const sessionData = JSON.parse(sessionDataString || "null");
  let token = sessionData?.access_token;

  if (window.localStorage.getItem("i")) {
    token = window.localStorage.getItem("i");
  }

  return token;
};

const auth = authExchange(async (utils) => {
  return {
    addAuthToOperation: (operation) => {
      const token = getToken();

      return utils.appendHeaders(operation, {
        Authorization: token ? `Bearer ${token}` : "",
      });
    },
    // TODO - implement the rest of the auth exchange
    didAuthError: () => false,
    willAuthError: () => false,
    refreshAuth: async () => {},
  };
});

const wsClient = createWSClient({
  url: "wss://" + import.meta.env.VITE_API_URL + "/graphql",
  connectionParams: () => {
    const token = getToken();
    return token ? { Authorization: `Bearer ${token}` } : {};
  },
});

export const graphqlClient = createClient({
  url: "https://" + import.meta.env.VITE_API_URL + "/graphql",
  fetchOptions: {
    credentials: "same-origin",
  },
  exchanges: [
    devtoolsExchange,
    cache,
    auth,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(request) {
        const input = { ...request, query: request.query || "" };
        return {
          subscribe(sink) {
            const unsubscribe = wsClient.subscribe(input, sink);

            wsClient.on("error", (error) => {
              console.error("The subscription errored:", error);
            });

            return { unsubscribe };
          },
        };
      },
    }),
  ],
});
