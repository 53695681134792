import * as CheckboxRadix from "@radix-ui/react-checkbox";

import { Icon } from "@/components/Icon/Icon";

import { checkboxIndicatorStyles, checkboxRootStyles } from "./Checkbox.css";

type CheckboxProps = CheckboxRadix.CheckboxProps;

export const Checkbox = ({ ...props }: CheckboxProps) => {
  return (
    <>
      <CheckboxRadix.Root className={checkboxRootStyles} {...props}>
        <CheckboxRadix.Indicator className={checkboxIndicatorStyles}>
          <Icon.Check />
        </CheckboxRadix.Indicator>
      </CheckboxRadix.Root>
    </>
  );
};
