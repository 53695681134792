import { assignInlineVars } from "@vanilla-extract/dynamic";

import {
  animatedLoadingShimmerStyles,
  criticalStyles,
  criticalWidthVar,
  oudatedWidthVar,
  outdatedStyles,
  securityStyles,
  securityWidthVar,
  statusBarStyles,
  updatedStyles,
  updatedWidthVar,
  wrapperStyles,
} from "./StatusBar.css";

type StatusBarProps = {
  updated?: number;
  outdated?: number;
  critical?: number;
  security?: number;
  isLoading?: boolean;
};

export const StatusBar = ({
  updated = 0,
  outdated = 0,
  critical = 0,
  security = 0,
  isLoading = false,
}: StatusBarProps) => {
  if (isLoading) {
    return (
      <div className={statusBarStyles}>
        <div className={wrapperStyles}>
          <div className={animatedLoadingShimmerStyles} />
        </div>
      </div>
    );
  }

  const total = updated + outdated + critical + security;

  const updatedPercentage = (updated / total) * 100;
  const outdatedPercentage = (outdated / total) * 100;
  const criticalPercentage = (critical / total) * 100;
  const securityPercentage = (security / total) * 100;

  return (
    <div className={statusBarStyles}>
      <div className={wrapperStyles}>
        <div
          className={updatedStyles}
          style={assignInlineVars({
            [updatedWidthVar]: `${updatedPercentage}%`,
          })}
        />
        <div
          className={outdatedStyles}
          style={assignInlineVars({
            [oudatedWidthVar]: `${outdatedPercentage}%`,
          })}
        />
        <div
          className={criticalStyles}
          style={assignInlineVars({
            [criticalWidthVar]: `${criticalPercentage}%`,
          })}
        />
        <div
          className={securityStyles}
          style={assignInlineVars({
            [securityWidthVar]: `${securityPercentage}%`,
          })}
        />
      </div>
    </div>
  );
};
