import { InviteEmails } from "@/components/InviteEmails/InviteEmails";

import { useInviteUsersToWorkspaceMutation } from "../../../../generated/graphql";

type InviteTeamProps = {
  onNext: () => void;
};

export const InviteTeam = ({ onNext }: InviteTeamProps) => {
  const [, inviteUsersToWorkspace] = useInviteUsersToWorkspaceMutation();

  const handleInvite = (emails: string[]) => {
    inviteUsersToWorkspace({ emails });
    onNext();
  };

  return (
    <div>
      <InviteEmails onInvite={handleInvite} />
    </div>
  );
};
