import { Table as TableType, flexRender } from "@tanstack/react-table";
import { Fragment } from "react";

import { Button } from "@/components/Button/Button";
import { StatusBar } from "@/components/StatusBar/StatusBar";
import { StatusTooltip } from "@/components/StatusTooltip/StatusTooltip";
import {
  tdStyles,
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import {
  Organization,
  RepositoryPreview,
  RepositoryState,
} from "@/generated/graphql";

import {
  automaticUpdatesStyles,
  repoNameStyles,
  tdOrganizationStyles,
} from "./Repositories.css";

type TableProps = {
  table: TableType<Organization>;
  onRowClick?: (
    row: RepositoryPreview,
    e: React.MouseEvent<HTMLTableRowElement>
  ) => void;
  onRepositoryConnect?: (row: RepositoryPreview) => void;
};

export const Table = ({
  table,
  onRowClick = () => null,
  onRepositoryConnect = () => null,
}: TableProps) => {
  return (
    <table className={wrapperStyles}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className={thStyles}
                key={header.id}
                style={{
                  width: header.column.columnDef.size,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          const repos = row.original?.repositories || [];
          // Non initialized repositories should be at the end of the list
          const sortedRepos = repos.sort(
            (a: RepositoryPreview, b: RepositoryPreview) => {
              if (a.state === RepositoryState.Uninitialized) return 1;
              if (b.state === RepositoryState.Uninitialized) return -1;

              return 0;
            }
          );

          return (
            <Fragment key={row.id}>
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={tdOrganizationStyles}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {sortedRepos.map((repo: RepositoryPreview) => {
                const isRepositoryProcessing =
                  repo.state === RepositoryState.Processing;
                const isRepositoryUninitialized =
                  repo.state === RepositoryState.Uninitialized;

                return (
                  <Tooltip
                    key={repo.id}
                    content={
                      isRepositoryProcessing ? (
                        "We're processing this repository. Please wait."
                      ) : isRepositoryUninitialized ? (
                        <>
                          This repository has not been scanned yet. Click on{" "}
                          <b>Connect</b> to start scanning.
                        </>
                      ) : (
                        <StatusTooltip
                          updated={repo.status.updated}
                          outdated={repo.status.outdated}
                          critical={repo.status.critical}
                          security={repo.status.security}
                        />
                      )
                    }
                  >
                    <tr
                      key={repo.id}
                      className={trStyles}
                      onClick={(e) => {
                        if (isRepositoryProcessing || isRepositoryUninitialized)
                          return;
                        onRowClick(repo, e);
                      }}
                    >
                      <td key={repo.id + "_name"} className={tdStyles}>
                        <span className={repoNameStyles}>{repo.name}</span>
                      </td>
                      <td key={repo.id + "_auto"} className={tdStyles}>
                        <div className={automaticUpdatesStyles}>
                          {repo.state === RepositoryState.Processed ? (
                            <VersionPill version="Enabled" type="success" />
                          ) : (
                            <VersionPill version="Disabled" type="warning" />
                          )}
                        </div>
                      </td>
                      <td className={tdStyles}>
                        {isRepositoryUninitialized ? (
                          <Button
                            hierarchy="secondary"
                            size="small"
                            onClick={() => onRepositoryConnect(repo)}
                          >
                            Connect
                          </Button>
                        ) : (
                          <StatusBar
                            updated={repo.status.updated}
                            outdated={repo.status.outdated}
                            critical={repo.status.critical}
                            security={repo.status.security}
                            isLoading={isRepositoryProcessing}
                          />
                        )}
                      </td>
                    </tr>
                  </Tooltip>
                );
              })}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
