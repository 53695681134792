import { DialogProps } from "@radix-ui/react-dialog";

import { Dialog } from "@/components/Dialog/Dialog";
import { Icon } from "@/components/Icon/Icon";
import { Markdown } from "@/components/Markdown/Markdown";

import { changelogStyles, contentStyles } from "./PreviewUpdates.css";

export type PreviewUpdatesProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  content?: string;
} & DialogProps;

export const PreviewUpdates = ({
  title,
  description,
  content,
  ...rest
}: PreviewUpdatesProps) => {
  return (
    <Dialog.Root {...rest} onOpenChange={rest.onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className={contentStyles}>
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Description>{description}</Dialog.Description>
          <Dialog.CloseIcon asChild>
            <Icon.Close />
          </Dialog.CloseIcon>
          <div className={changelogStyles}>
            <Markdown>{content}</Markdown>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
