import { Button } from "@/components/Button/Button";
import { Icon } from "@/components/Icon/Icon";
import { connectGithubRepository } from "@/utils";

import {
  descriptionStyles,
  emptyBackgroundImageStyles,
  titleStyles,
  wrapperStyles,
} from "./EmptyTable.css";

export const EmptyTable = () => (
  <div className={wrapperStyles}>
    <img
      src="/img/empty.svg"
      alt="empty background pattern"
      className={emptyBackgroundImageStyles}
    />

    <div className={titleStyles}>
      There are no repositories in this workspace.
    </div>
    <div className={descriptionStyles}>
      Add a new repository to start managing your dependencies.
    </div>
    <Button onClick={connectGithubRepository} icon={<Icon.Github />}>
      Connect repository
    </Button>
  </div>
);
