export const FileSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 3.02463V7.15517C14 7.71522 14 7.99525 14.109 8.20916C14.2049 8.39732 14.3578 8.5503 14.546 8.64617C14.7599 8.75517 15.0399 8.75517 15.6 8.75517H19.7305M16 13.7551H8M16 17.7551H8M10 9.7551H8M14 2.7551H8.8C7.11984 2.7551 6.27976 2.7551 5.63803 3.08208C5.07354 3.3697 4.6146 3.82864 4.32698 4.39312C4 5.03486 4 5.87494 4 7.5551V17.9551C4 19.6353 4 20.4753 4.32698 21.1171C4.6146 21.6816 5.07354 22.1405 5.63803 22.4281C6.27976 22.7551 7.11984 22.7551 8.8 22.7551H15.2C16.8802 22.7551 17.7202 22.7551 18.362 22.4281C18.9265 22.1405 19.3854 21.6816 19.673 21.1171C20 20.4753 20 19.6353 20 17.9551V8.7551L14 2.7551Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
