import * as HoverCardRadix from "@radix-ui/react-hover-card";

import {
  cardArrowStyles,
  hoverCardContentStyles,
  triggerStyles,
} from "./HoverCard.css";

type HoverCardProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  rootProps?: React.ComponentProps<typeof HoverCardRadix.Root>;
};

export const HoverCard = ({ children, content, rootProps }: HoverCardProps) => (
  <HoverCardRadix.Root {...rootProps}>
    <HoverCardRadix.Trigger asChild>
      <div className={triggerStyles}>{children}</div>
    </HoverCardRadix.Trigger>
    <HoverCardRadix.Portal>
      <HoverCardRadix.Content className={hoverCardContentStyles} sideOffset={5}>
        {content}
        <HoverCardRadix.Arrow className={cardArrowStyles} />
      </HoverCardRadix.Content>
    </HoverCardRadix.Portal>
  </HoverCardRadix.Root>
);
