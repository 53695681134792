import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Avatar } from "@/components/Avatar/Avatar";
import { Button } from "@/components/Button/Button";
import { Dialog, useDialogContext } from "@/components/Dialog/DialogContext";
import {
  tdStyles,
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import {
  WorkspaceMember,
  WorkspaceMemberStatus,
  useRevokeWorkspaceInvitationMutation,
  useRootQueryQuery,
} from "@/generated/graphql";
import { getInitials } from "@/utils";

import { inviteHeaderStyles, memberNameStyles } from "./Members.css";

const columnHelper = createColumnHelper<WorkspaceMember>();

export const Members = () => {
  const [{ data }] = useRootQueryQuery();
  const workspace = data?.workspace;
  const { openDialog } = useDialogContext();
  const [, revokeWorkspaceInvitationMutation] =
    useRevokeWorkspaceInvitationMutation();

  const handleInvitationRevoke = (id: string) => {
    revokeWorkspaceInvitationMutation({
      invitationId: id,
    });
  };

  const columns = [
    columnHelper.accessor("name", {
      header: () => <>Name</>,
      cell: (info) => (
        <span className={memberNameStyles}>
          <Avatar
            src={info.row.original.profileImgHref ?? ""}
            fallback={getInitials(info.row.original.name ?? "")}
            size={24}
          />
          {info.getValue() ? info.getValue() : info.row.original.email}
        </span>
      ),
      maxSize: 100,
      size: 100,
      enableGrouping: true,
    }),
    columnHelper.accessor("email", {
      header: () => <>Email</>,
      cell: (info) => (
        <span className={memberNameStyles}>{info.getValue()}</span>
      ),
      maxSize: 100,
      size: 100,
      enableGrouping: true,
    }),
    columnHelper.accessor("status", {
      header: () => <>Status</>,
      cell: ({ row }) => {
        return row.original.status === WorkspaceMemberStatus.Active ? (
          <span>Member</span>
        ) : (
          <span>Pending</span>
        );
      },
    }),
    columnHelper.accessor("id", {
      header: () => <>Action</>,
      cell: ({ row }) => {
        return (
          row.original.status === WorkspaceMemberStatus.Invited && (
            <Button
              hierarchy="secondary"
              size="small"
              onClick={() => handleInvitationRevoke(row.original.id)}
            >
              Revoke invitation
            </Button>
          )
        );
      },
    }),
  ];

  const table = useReactTable({
    data: workspace?.members ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false,
  });

  const handleInviteMembers = () => {
    openDialog(Dialog.InviteMembers);
  };

  return (
    <>
      <div className={inviteHeaderStyles}>
        <Button onClick={handleInviteMembers}>Invite new members</Button>
      </div>
      <table className={wrapperStyles}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className={thStyles}
                  key={header.id}
                  style={{
                    width: header.column.columnDef.size,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr className={trStyles} key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={tdStyles}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
