import { Outlet } from "react-router-dom";

import { wrapperStyles } from "./FullScreenLayout.css";

export const FullScreenLayout = () => {
  return (
    <div className={wrapperStyles}>
      <Outlet />
    </div>
  );
};
