import { Card } from "@/components/Card/Card";
import { LineChart } from "@/components/LineChart/LineChart";
import { Markdown } from "@/components/Markdown/Markdown";
import { ScoreRadialBar } from "@/components/ScoreRadialBar/ScoreRadialBar";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import { Package } from "@/generated/graphql";

import {
  aliasesWrapperStyles,
  cardPointStyles,
  cardStyles,
  cardSubtitleStyles,
  chartStyles,
  contentStyles,
  detailsStyles,
  infoPanelStyles,
  referenceUrlWrapperStyles,
  truncatedPillStyles,
  wrapperStyles,
} from "./Overview.css";

type OverviewProps = {
  pkg: Package;
};

export const Overview = ({ pkg }: OverviewProps) => {
  const { total, security, maintenance, license } = pkg.score;

  const chartData = pkg.history.map((historyPoint) => ({
    name: historyPoint.date,
    value: historyPoint.value,
  }));

  return (
    <div className={wrapperStyles}>
      <div className={contentStyles}>
        <div className={aliasesWrapperStyles}>
          {pkg.keywords.map((keyword) => (
            <VersionPill version={keyword} key={keyword} />
          ))}
        </div>
        <div className={detailsStyles}>
          <Markdown>{pkg.readme ?? ""}</Markdown>
        </div>
      </div>
      <div className={infoPanelStyles}>
        <Card>
          <div className={cardStyles}>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Downloads</div>
              <div className={chartStyles}>
                <LineChart data={chartData} goal="max" />
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className={cardStyles}>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Total score</div>
              <ScoreRadialBar value={total} />
            </div>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Security</div>
              <ScoreRadialBar value={security} />
            </div>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Maintenance</div>
              <ScoreRadialBar value={maintenance} />
            </div>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>License</div>
              <ScoreRadialBar value={license} />
            </div>
          </div>
        </Card>
        <Card>
          <div className={cardStyles}>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Homepage</div>
              <a
                href={pkg.homepage}
                target="_blank"
                rel="noopener noreferrer"
                className={referenceUrlWrapperStyles}
              >
                <VersionPill
                  version={`${pkg.homepage}`}
                  className={truncatedPillStyles}
                />
              </a>
            </div>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Repository</div>
              <a
                href={pkg.repository}
                target="_blank"
                rel="noopener noreferrer"
                className={referenceUrlWrapperStyles}
              >
                <VersionPill
                  version={`${pkg.repository}`}
                  className={truncatedPillStyles}
                />
              </a>
            </div>
          </div>
        </Card>
        <Card>
          <div className={cardStyles}>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Maintainers</div>
              {pkg.maintainers.map((maintainer) => (
                <VersionPill
                  version={maintainer}
                  className={truncatedPillStyles}
                  key={maintainer}
                />
              ))}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
