import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { supabase } from "@/providers/AuthProvider";
import { vars } from "@/styles/theme.css";

import {
  additionalInfoStyles,
  descriptionStyles,
  logoStyles,
  titleStyles,
  wrapperStyles,
} from "./styles.css";

export const SignUp = () => {
  return (
    <>
      <Helmet>
        <title>Sign up - DepsHub</title>
      </Helmet>
      <div className={wrapperStyles}>
        <a href="/">
          <img className={logoStyles} src="logo.svg" />
        </a>
        <div className={titleStyles}>Sign up</div>
        <div className={descriptionStyles}>
          Use any of the providers below to continue.
        </div>
        <Auth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: vars.color.primary9,
                  brandAccent: vars.color.primary9,
                  brandButtonText: vars.color.gray12,
                },
              },
            },
          }}
          providers={["github"]}
          onlyThirdPartyProviders
          redirectTo={window.location.origin + "/onboarding"}
          showLinks={false}
          view="sign_up"
          theme="dark"
        />
        <div className={additionalInfoStyles}>
          Already have an account? <Link to="/login">Sign in</Link>
        </div>
      </div>
    </>
  );
};
