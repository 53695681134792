export const TerminalSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 15.7551L10 12.7551L7 9.7551M13 15.7551H17M7.8 21.7551H16.2C17.8802 21.7551 18.7202 21.7551 19.362 21.4281C19.9265 21.1405 20.3854 20.6816 20.673 20.1171C21 19.4753 21 18.6353 21 16.9551V8.5551C21 6.87494 21 6.03486 20.673 5.39312C20.3854 4.82864 19.9265 4.3697 19.362 4.08208C18.7202 3.7551 17.8802 3.7551 16.2 3.7551H7.8C6.11984 3.7551 5.27976 3.7551 4.63803 4.08208C4.07354 4.3697 3.6146 4.82864 3.32698 5.39312C3 6.03486 3 6.87494 3 8.5551V16.9551C3 18.6353 3 19.4753 3.32698 20.1171C3.6146 20.6816 4.07354 21.1405 4.63803 21.4281C5.27976 21.7551 6.11984 21.7551 7.8 21.7551Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
