import { Navigate } from "react-router-dom";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { AuthLayout } from "@/layouts/AuthLayout/AuthLayout";
import { ExternalLayout } from "@/layouts/ExternalLayout/ExternalLayout";
import { SignIn } from "@/pages/Auth/SignIn";
import { SignUp } from "@/pages/Auth/SignUp";
import { Invitation } from "@/pages/Invitation/Invitation";

export const loggedOutRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<SignIn />} />
        <Route path="register" element={<SignUp />} />
      </Route>

      <Route element={<ExternalLayout />}>
        <Route path="invitation/:code" element={<Invitation />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </>
  )
);
