import { formatDistance } from "date-fns";

import {
  Severity,
  SeverityAvailability,
  SeverityComplexity,
  SeverityIntegrity,
  SeverityPrivileges,
} from "@/generated/graphql";

export const formatPublished = (published: string, addSuffix = true) =>
  formatDistance(new Date(published), new Date(), { addSuffix: addSuffix });

export const formatComplexity = (severity: Severity[]) => {
  const complexity = severity[0].complexity;

  switch (complexity) {
    case SeverityComplexity.Low:
      return "Low";
    case SeverityComplexity.High:
      return "High";
    default:
      return "Unknown";
  }
};

export const formatIntegrity = (severity: Severity[]) => {
  const integrity = severity[0].integrity;

  switch (integrity) {
    case SeverityIntegrity.Low:
      return "Low";
    case SeverityIntegrity.High:
      return "High";
    case SeverityIntegrity.None:
      return "None";
    default:
      return "Unknown";
  }
};

export const formatAvailability = (severity: Severity[]) => {
  const availability = severity[0].availability;

  switch (availability) {
    case SeverityAvailability.Low:
      return "Low";
    case SeverityAvailability.High:
      return "High";
    case SeverityAvailability.None:
      return "None";
    default:
      return "Unknown";
  }
};

export const formatPrivileges = (severity: Severity[]) => {
  const privileges = severity[0].privileges;

  switch (privileges) {
    case SeverityPrivileges.Low:
      return "Low";
    case SeverityPrivileges.High:
      return "High";
    case SeverityPrivileges.None:
      return "None";
    default:
      return "Unknown";
  }
};
