import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import {
  tdStyles,
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import { LicensesQuery } from "@/generated/graphql";
import { pluralize } from "@/utils";

import { complianceStyles, nameStyles, totalStyles } from "./Licenses.css";

type LicensesTableProps = {
  data?: LicensesQuery;
};

type LicenseRow = {
  name: string;
  count: number;
  compliance: boolean;
};

const columnHelper = createColumnHelper<LicenseRow>();

const columns = [
  columnHelper.accessor("name", {
    header: () => <>License type</>,
    cell: ({ row }) => (
      <>
        <div className={nameStyles}>{row.original.name}</div>
        <div className={totalStyles}>
          {row.original.count}{" "}
          {pluralize(row.original.count, "package", "packages")}
        </div>
      </>
    ),
    maxSize: 100,
    size: 100,
    enableGrouping: true,
  }),
  columnHelper.accessor("count", {
    header: () => <>Policy compliance</>,
    cell: ({ row }) => (
      <>
        <div className={complianceStyles}>
          {row.original.name !== "Unknown" && (
            <VersionPill
              version={row.original.compliance ? "Yes" : "No"}
              type={row.original.compliance ? "success" : "error"}
            />
          )}
        </div>
      </>
    ),
  }),
];

export const LicensesTable = ({ data }: LicensesTableProps) => {
  const table = useReactTable({
    data: data?.licenses ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false,
  });
  const navigate = useNavigate();

  const handleRowClick = (licenseName: string, e: React.MouseEvent) => {
    const url = `/licenses/${licenseName}`;

    // TODO Handle middle mouse click
    // https://github.com/TanStack/table/discussions/2336#discussioncomment-2948209
    //
    // Handle Cmd + Click
    if (e.metaKey || e.ctrlKey) {
      return window.open(url, "_blank");
    }
    navigate(url);
  };

  return (
    <table className={wrapperStyles}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className={thStyles}
                key={header.id}
                style={{
                  width: header.column.columnDef.size,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr
              key={row.id}
              className={trStyles}
              onClick={(e) => handleRowClick(row.original.name, e)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={tdStyles}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
