import { DialogProps } from "@radix-ui/react-dialog";
import { useState } from "react";

import { Button } from "@/components/Button/Button";
import { Dialog } from "@/components/Dialog/Dialog";
import { Icon } from "@/components/Icon/Icon";
import { Input } from "@/components/Input/Input";
import {
  useCreateWorkspaceMutation,
  useSelectWorkspaceMutation,
} from "@/generated/graphql";

import {
  buttonsWrapperStyles,
  contentStyles,
  formStyles,
} from "./CreateWorkspace.css";

export type CreateWorkspaceProps = DialogProps;

export const CreateWorkspace = ({
  onOpenChange,
  ...rest
}: CreateWorkspaceProps) => {
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [, createWorkspaceMutation] = useCreateWorkspaceMutation();
  const [, selectWorkspaceMutation] = useSelectWorkspaceMutation();

  const handleWorkspaceNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWorkspaceName(e.target.value);
  };

  const handleClose = () => {
    onOpenChange && onOpenChange(false);
  };

  const handleCreateWorkspace = async () => {
    const response = await createWorkspaceMutation({
      name: workspaceName,
    });

    if (response.data?.createWorkspace?.id) {
      selectWorkspaceMutation({
        id: response.data.createWorkspace.id,
      });
    }

    window.location.reload();
  };

  return (
    <Dialog.Root {...rest} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className={contentStyles}>
          <Dialog.Title>Create a new workspace</Dialog.Title>
          <Dialog.Description>
            Workspaces allow you to organize your repositories and team members
            into groups.
          </Dialog.Description>
          <Dialog.CloseIcon asChild>
            <Icon.Close />
          </Dialog.CloseIcon>
          <div className={formStyles}>
            <Input
              placeholder="Enter your workspace name"
              style={{ width: "100%" }}
              onChange={handleWorkspaceNameChange}
              value={workspaceName}
            />
          </div>
          <div className={buttonsWrapperStyles}>
            <Button hierarchy="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              hierarchy="primary"
              disabled={!workspaceName}
              onClick={handleCreateWorkspace}
            >
              Create
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
