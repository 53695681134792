import { useState } from "react";
import { Helmet } from "react-helmet-async";

import { Button } from "@/components/Button/Button";
import { PaginationSteps } from "@/components/PaginationSteps/PaginationSteps";

import {
  contentStyles,
  descriptionStyles,
  paginationStyles,
  skipButtonStyles,
  titleStyles,
  wrapperStyles,
} from "./Onboarding.css";
import { ConnectRepositories } from "./steps/ConnectRepositories";
import { InviteTeam } from "./steps/InviteTeam";
import { WorkspaceName } from "./steps/WorkspaceName";

export const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    if (currentStep === onboardingSteps.length - 1) {
      window.location.assign("/");
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const onboardingSteps = [
    {
      id: "workspace-name",
      title: "Workspace name",
      description:
        "First, you need to name your workspace. You can change it later.",
      content: <WorkspaceName onNext={handleNextStep} />,
      isSkippable: false,
    },
    {
      id: "invite-team",
      title: "Invite team",
      description: "Invite your team to help you manage your dependencies.",
      content: <InviteTeam onNext={handleNextStep} />,
      isSkippable: true,
    },
    {
      id: "connect-repositories",
      title: "Connect repositories",
      description: "Connect your repositories to start working with them.",
      content: <ConnectRepositories onNext={handleNextStep} />,
      isSkippable: true,
    },
  ];

  const currentOnboardingStep = onboardingSteps[currentStep];

  return (
    <>
      <Helmet>
        <title>Onboarding - DepsHub</title>
      </Helmet>
      <div className={wrapperStyles}>
        <div className={titleStyles}>
          {currentOnboardingStep.title}
          <div className={descriptionStyles}>
            {currentOnboardingStep.description}
          </div>
        </div>
        <div className={contentStyles}>{currentOnboardingStep.content}</div>
        <div className={paginationStyles}>
          <PaginationSteps
            steps={onboardingSteps.length}
            currentStep={currentStep}
            onChange={() => {}}
          />
          {currentOnboardingStep.isSkippable && (
            <Button
              className={skipButtonStyles}
              onClick={handleNextStep}
              hierarchy="secondary"
              size="small"
            >
              {currentStep === onboardingSteps.length - 1 ? "Finish" : "Skip"}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
