import {
  Bar,
  BarChart as BarChartRecharts,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { vars } from "@/styles/theme.css";

export type BarChartData = {
  name: string;
  a: number;
  b: number;
  c: number;
  d: number;
}[];

export type BarChartProps = {
  data: BarChartData;
};

interface TickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
}

const Tick: React.FC<TickProps> = ({ x, y, payload }: TickProps) => {
  if (x === undefined || y === undefined || payload === undefined) {
    return null;
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        dx={-10}
        fontSize={14}
        textAnchor="middle"
        fill={vars.color.gray9}
      >
        {payload.value}
      </text>
    </g>
  );
};

export const BarChart = ({ data }: BarChartProps) => {
  // Do not render if there is no data
  if (!data.length) return null;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChartRecharts
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        maxBarSize={5}
        barGap={0}
      >
        <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
        <XAxis dataKey="name" opacity={0.7} axisLine={false} tick={<Tick />} />
        <YAxis opacity={0.7} axisLine={false} tick={<Tick />} />

        <Bar dataKey="a" stackId="a" fill={vars.color.success9} />
        <Bar dataKey="b" stackId="a" fill={vars.color.success7} />
        <Bar dataKey="c" stackId="a" fill={vars.color.warning9} />
        <Bar dataKey="d" stackId="a" fill={vars.color.error9} />
      </BarChartRecharts>
    </ResponsiveContainer>
  );
};
