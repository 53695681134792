export const SignalSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.2426 8.41046C18.5858 10.7536 18.5858 14.5526 16.2426 16.8957M7.75736 16.8957C5.41421 14.5526 5.41421 10.7536 7.75736 8.41042M4.92893 19.7242C1.02369 15.8189 1.02369 9.48727 4.92893 5.58203M19.0711 5.58208C22.9763 9.48732 22.9763 15.819 19.0711 19.7242M14 12.6531C14 13.7577 13.1046 14.6531 12 14.6531C10.8954 14.6531 10 13.7577 10 12.6531C10 11.5485 10.8954 10.6531 12 10.6531C13.1046 10.6531 14 11.5485 14 12.6531Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
