import {
  ExpandedState,
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { Fragment } from "react";

import { Button } from "@/components/Button/Button";
import { Icon } from "@/components/Icon/Icon";
import { Input } from "@/components/Input/Input";
import { StatusBar } from "@/components/StatusBar/StatusBar";
import { StatusTooltip } from "@/components/StatusTooltip/StatusTooltip";
import {
  expandedTrStyles,
  tdStyles,
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import { useToast } from "@/components/Toast/Toast";
import { ToggleGroup } from "@/components/ToggleGroup/ToggleGroup";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import {
  RepositoryQuery,
  UpdatePackagesInput,
  useRepositoryUpdatedSubscription,
  useUpdatePackagesMutation,
} from "@/generated/graphql";
import { getManifestTypeImg, pluralize } from "@/utils";

import {
  emptyStateWrapper,
  filterWrapperStyles,
  manifestNameComingSoonStyles,
  manifestNameStyles,
  manifestNameWrapperStyles,
  manifestNotSupportedStyles,
  manifestTotalStyles,
  updateButtonStyles,
  updateButtonWrapperStyles,
} from "./Dependencies.css";
import { DependenciesTable, DependencyRow } from "./DependenciesTable";

type Manifest = RepositoryQuery["repository"]["manifestFiles"][0];

type RepositoryColumn = Manifest & {
  total: number;
};

const columnHelper = createColumnHelper<RepositoryColumn>();

const columns = [
  columnHelper.accessor("path", {
    header: () => <>Manifest file</>,
    cell: ({ getValue, row }) => (
      <div>
        <span className={manifestNameWrapperStyles}>
          <img src={getManifestTypeImg(row.original.manifestType)} />
          <div>
            {row.original.isSupported ? (
              <>
                <div className={manifestNameStyles}>{getValue()}</div>
                <div className={manifestTotalStyles}>
                  {row.original.total}{" "}
                  {pluralize(row.original.total, "dependency", "dependencies")}
                </div>
              </>
            ) : (
              <>
                <div className={manifestNameComingSoonStyles}>{getValue()}</div>
                <div className={manifestNotSupportedStyles}>Coming soon</div>
              </>
            )}
          </div>
        </span>
      </div>
    ),
    maxSize: 100,
    size: 100,
  }),
  columnHelper.accessor("status", {
    header: () => <>Status</>,
    cell: ({ row }) => {
      const counts = getCounts(row.original.dependencies);

      return (
        <div>
          <StatusBar
            updated={counts.updated}
            outdated={counts.outdated}
            critical={counts.critical}
            security={counts.security}
          />
        </div>
      );
    },
  }),
];

type DependenciesProps = {
  data?: RepositoryQuery;
};

export const Dependencies = ({ data }: DependenciesProps) => {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [selectedPackages, setSelectedPackages] = React.useState<
    DependencyRow[]
  >([]);
  const [, updatePackagesMutation] = useUpdatePackagesMutation();
  const [depsTableKey, setDepsTableKey] = React.useState<number>(0);
  const { showToast } = useToast();

  // Filters
  const [search, setSearch] = React.useState<string>("");
  const [packageType, setPackageType] = React.useState<string>("all");

  useRepositoryUpdatedSubscription({
    variables: { id: data?.repository.id ?? "" },
  });

  const dataToUse = React.useMemo(
    () =>
      (data?.repository?.manifestFiles ?? [])
        .map((manifestFile) => {
          const dependencies = manifestFile.dependencies
            .filter(
              (dependency) =>
                // Every filter should be true so we use && operator
                // Name filter
                ((dependency?.package?.name ?? "")
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                  // Package type filter
                  (packageType === "all" || dependency.type === packageType)) ||
                // Always show selected packages
                // (notice the || before this filter)
                selectedPackages.find(
                  (p) => p.manifestPackageId === dependency.id
                )
            )
            // The order is the following:
            // - security
            // - major
            // - minor
            // - patch
            .sort((a, b) => {
              if (a.upgrade?.type === "security") {
                return -1;
              }
              if (b.upgrade?.type === "security") {
                return 1;
              }

              if (a.upgrade?.type === "major") {
                return -1;
              }
              if (b.upgrade?.type === "major") {
                return 1;
              }

              if (a.upgrade?.type === "minor") {
                return -1;
              }
              if (b.upgrade?.type === "minor") {
                return 1;
              }

              if (a.upgrade?.type === "patch") {
                return -1;
              }
              if (b.upgrade?.type === "patch") {
                return 1;
              }

              return 0;
            });

          return {
            ...manifestFile,
            total: dependencies.length,
            dependencies,
          };
        })
        .sort((a, b) => b.total - a.total),
    [data?.repository?.manifestFiles, search, packageType]
  );

  const table = useReactTable({
    data: dataToUse,
    columns,
    state: {
      expanded,
    },
    getRowCanExpand: (row) => row.original.total > 0,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugAll: false,
  });

  const handleUpdatePackages = () => {
    showToast({
      title: `Updating ${selectedPackages.length} ${pluralize(
        selectedPackages.length,
        "package",
        "packages"
      )}`,
      description: "This may take up to a minute.",
    });

    updatePackagesMutation({
      packages: selectedPackages.map(
        (packageVersion) =>
          ({
            manifestPackageId: packageVersion.manifestPackageId,
            packageVersionId: packageVersion.selectedVersionId,
          } as UpdatePackagesInput)
      ),
    });

    setDepsTableKey(Math.random());
    setSelectedPackages([]);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    table.toggleAllRowsExpanded(!!value);
  };

  const handleSelectPackage = (packageVersion: DependencyRow) => {
    setSelectedPackages((prev) => {
      if (
        prev.find(
          (p) => p.manifestPackageId === packageVersion.manifestPackageId
        )
      ) {
        return prev.filter(
          (p) => p.manifestPackageId !== packageVersion.manifestPackageId
        );
      }

      return [...prev, packageVersion];
    });
  };

  const handleClearSelection = () => {
    setSelectedPackages([]);
    setDepsTableKey(Math.random());
  };

  const filterItems = [
    {
      value: "all",
      label: "All",
      ariaLabel: "Show all dependencies",
    },
    {
      value: "regular",
      label: "Production",
      ariaLabel: "Show only production dependencies",
    },
    {
      value: "dev",
      label: "Development",
      ariaLabel: "Show only development dependencies",
    },
  ];

  const handleFilterTypeChange = (value: string) => {
    setPackageType(value);
  };

  const noSupportedManifests =
    data?.repository?.manifestFiles.filter((manifest) => manifest.isSupported)
      .length === 0;

  return (
    <>
      <div className={updateButtonWrapperStyles}>
        <div className={filterWrapperStyles}>
          <Input
            placeholder="Search"
            icon={<Icon.Search />}
            onChange={handleSearch}
          />
          <ToggleGroup
            items={filterItems}
            value={packageType}
            ariaLabel="Filter dependencies"
            onChange={handleFilterTypeChange}
          />
        </div>
        <div className={updateButtonStyles}>
          {selectedPackages.length > 0 && (
            <Button onClick={handleClearSelection} hierarchy="secondary">
              Clear selection
            </Button>
          )}
          {selectedPackages.length > 0 && (
            <Button icon={<Icon.Update />} onClick={handleUpdatePackages}>
              Update{" "}
              {selectedPackages.length > 1
                ? `(${selectedPackages.length})`
                : ""}
            </Button>
          )}
        </div>
      </div>
      {noSupportedManifests && (
        <div className={emptyStateWrapper}>
          No supported manifest files. Please, check if you have a supported
          manifest file in your repository.
        </div>
      )}
      <table className={wrapperStyles}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className={thStyles}
                  key={header.id}
                  style={{
                    width: header.column.columnDef.size,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const counts = getCounts(row.original.dependencies);
            const tableRow = (
              <tr
                className={row.getIsExpanded() ? expandedTrStyles : trStyles}
                onClick={() => {
                  if (row.original.total === 0) return;
                  row.toggleExpanded();
                }}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={tdStyles}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );

            if (row.original.total === 0) {
              return tableRow;
            }

            return (
              <Fragment key={row.id}>
                <Tooltip
                  content={
                    <StatusTooltip
                      updated={counts.updated}
                      outdated={counts.outdated}
                      critical={counts.critical}
                      security={counts.security}
                    />
                  }
                >
                  {tableRow}
                </Tooltip>

                <DependenciesTableWithData
                  row={row}
                  key={depsTableKey}
                  handleSelectPackage={handleSelectPackage}
                  isRendered={row.getIsExpanded()}
                />
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

type Dependency = Manifest["dependencies"][0];

const getCounts = (dependencies: Dependency[]) => {
  let { updated, outdated, critical, security } = {
    updated: 0,
    outdated: 0,
    critical: 0,
    security: 0,
  };
  dependencies.forEach((dependency) => {
    if (dependency.upgrade?.type === "patch") {
      outdated++;
    }
    if (dependency.upgrade?.type === "minor") {
      outdated++;
    }
    if (dependency.upgrade?.type === "major") {
      critical++;
    }
    if (dependency.upgrade?.type === "security") {
      security++;
    }

    if (!dependency.upgrade?.type) {
      updated++;
    }
  });

  return { updated, outdated, critical, security };
};

type DependenciesTableWithDataProps = {
  row: Row<RepositoryColumn>;
  handleSelectPackage: (packageVersion: DependencyRow, path: string) => void;
  isRendered: boolean;
};

function DependenciesTableWithData({
  row,
  handleSelectPackage,
  isRendered,
}: DependenciesTableWithDataProps) {
  const [rowSelection, setRowSelection] = React.useState({});
  if (!isRendered) return null;

  return (
    <tr>
      <td colSpan={row.getVisibleCells().length}>
        <DependenciesTable
          dependencies={row.original.dependencies}
          setSelectedPackage={(packageVersion) =>
            handleSelectPackage(packageVersion, row.original.path)
          }
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </td>
    </tr>
  );
}
