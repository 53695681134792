import ReactMarkdown, { Options } from "react-markdown";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import markdown from "react-syntax-highlighter/dist/esm/languages/hljs/markdown";
import theme from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

import { linkStyles } from "./Markdown.css";

SyntaxHighlighter.registerLanguage("markdown", markdown);

type MarkdownProps = Options;

export const Markdown = ({ children }: MarkdownProps) => {
  return (
    <ReactMarkdown
      components={{
        a(props) {
          return <a className={linkStyles} {...props} />;
        },
        code(props) {
          const { children, className, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          return match ? (
            // @ts-expect-error: Suppressing error due to specific case handled
            <SyntaxHighlighter
              {...rest}
              PreTag="div"
              children={String(children).replace(/\n$/, "")}
              language={match[1]}
              style={theme}
            />
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
