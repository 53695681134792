import { Item, Root } from "@radix-ui/react-toggle-group";

import { toggleGroupItemStyles, toggleGroupStyles } from "./ToggleGroup.css";

type ToggleGroupProps = {
  items: {
    label: string;
    value: string;
    ariaLabel: string;
  }[];
  ariaLabel: string;
  value?: string;
  onChange?: (value: string) => void;
};

export const ToggleGroup = ({
  items = [],
  value,
  ariaLabel,
  onChange = () => null,
}: ToggleGroupProps) => {
  return (
    <Root
      className={toggleGroupStyles}
      type="single"
      value={value}
      onValueChange={(value) => {
        if (value) onChange(value);
      }}
      aria-label={ariaLabel}
    >
      {items.map((item) => (
        <Item
          className={toggleGroupItemStyles}
          value={item.value}
          aria-label={item.ariaLabel}
          key={item.value}
        >
          {item.label}
        </Item>
      ))}
    </Root>
  );
};
