import * as DialogRadix from "@radix-ui/react-dialog";
import classnames from "classnames";
import { forwardRef } from "react";

import {
  closeStyles,
  contentStyles,
  descriptionStyles,
  overlayStyles,
  titleStyles,
} from "./Dialog.css";

export const Dialog = {
  Root: DialogRadix.Root,
  Portal: DialogRadix.Portal,
  Trigger: DialogRadix.Trigger,
  Close: DialogRadix.Close,
  CloseIcon: forwardRef<HTMLButtonElement, DialogRadix.DialogCloseProps>(
    (props, ref) => (
      <DialogRadix.Close
        {...props}
        className={classnames([closeStyles, props?.className])}
        ref={ref}
      />
    )
  ),
  Overlay: forwardRef<HTMLDivElement, DialogRadix.DialogOverlayProps>(
    (props, ref) => (
      <DialogRadix.Overlay
        {...props}
        className={classnames([overlayStyles, props?.className])}
        ref={ref}
      />
    )
  ),
  Content: forwardRef<HTMLDivElement, DialogRadix.DialogContentProps>(
    (props, ref) => (
      <DialogRadix.Content
        {...props}
        className={classnames([contentStyles, props?.className])}
        ref={ref}
      />
    )
  ),
  Title: forwardRef<HTMLHeadingElement, DialogRadix.DialogTitleProps>(
    (props, ref) => (
      <DialogRadix.Title
        {...props}
        className={classnames([titleStyles, props?.className])}
        ref={ref}
      />
    )
  ),
  Description: forwardRef<HTMLParagraphElement, DialogRadix.DialogTitleProps>(
    (props, ref) => (
      <DialogRadix.Description
        {...props}
        className={classnames([descriptionStyles, props?.className])}
        ref={ref}
      />
    )
  ),
};
