import { stepStyles, wrapperStyles } from "./PaginationSteps.css";

type PaginationStepsProps = {
  steps: number;
  currentStep: number;
  onChange: (step: number) => void;
};

export const PaginationSteps = ({
  steps,
  currentStep,
  onChange,
}: PaginationStepsProps) => {
  return (
    <div className={wrapperStyles}>
      {Array.from({ length: steps }, (_, i) => (
        <button
          key={i}
          onClick={() => onChange(i)}
          className={i <= currentStep ? stepStyles.Active : stepStyles.Default}
        />
      ))}
    </div>
  );
};
