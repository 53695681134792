import * as Tabs from "@radix-ui/react-tabs";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { Icon } from "@/components/Icon/Icon";
import { NotificationBadge } from "@/components/NotificationBadge/NotificationBadge";
import {
  tabsContentStyles,
  tabsListStyles,
  tabsTriggerStyles,
} from "@/components/Tabs/Tabs.css";
import { useRepositoryQuery } from "@/generated/graphql";
import { AppLayout } from "@/layouts/AppLayout/AppLayout";

import { Configuration } from "./Configuration/Configuration";
import { Dependencies } from "./Dependencies/Dependencies";
import {
  countStyles,
  iconLinkStyles,
  tabsRootStylesRepos,
} from "./Repository.css";
import { Security } from "./Security/Security";

export const Repository = () => {
  const { repositoryId } = useParams<{ repositoryId: string }>();

  const [{ data }] = useRepositoryQuery({
    pause: !repositoryId,
    variables: {
      id: repositoryId ?? "",
    },
  });

  const percentage = React.useMemo(() => {
    const status = data?.repository?.status;

    if (!status) {
      return 0;
    }

    const total =
      status.updated + status.outdated + status.critical + status.security;

    return ((status.updated ?? 0) / (total ?? 1)) * 100 || 0;
  }, [data?.repository?.status]);

  const settings = data?.repository?.settings;

  return (
    <>
      <Helmet>
        <title>{data?.repository.name ?? "Repository"} - DepsHub</title>
      </Helmet>
      <AppLayout.Header
        title={data?.repository.name}
        icon={
          <a
            href={data?.repository.url ?? ""}
            target="_blank"
            rel="noopener noreferrer"
            className={iconLinkStyles}
          >
            <Icon.Github />
          </a>
        }
        description={
          <div>
            You have{" "}
            <span className={countStyles}>
              {percentage > 0 && <> {percentage.toFixed(0)}% </>}
            </span>
            dependencies up-to-date in this repository.
          </div>
        }
      />
      <Tabs.Root className={tabsRootStylesRepos} defaultValue="dependencies">
        <Tabs.List className={tabsListStyles}>
          <Tabs.Trigger className={tabsTriggerStyles} value="dependencies">
            Dependencies
          </Tabs.Trigger>
          <Tabs.Trigger className={tabsTriggerStyles} value="security">
            Security{" "}
            <NotificationBadge count={data?.repository.status.security} />
          </Tabs.Trigger>
          <Tabs.Trigger className={tabsTriggerStyles} value="configuration">
            Configuration
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content className={tabsContentStyles} value="dependencies">
          <Dependencies data={data} />
        </Tabs.Content>
        <Tabs.Content className={tabsContentStyles} value="security">
          <Security data={data} />
        </Tabs.Content>
        <Tabs.Content className={tabsContentStyles} value="configuration">
          <Configuration settings={settings} />
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};
