import * as RadixToast from "@radix-ui/react-toast";
import React from "react";

import {
  toastDescriptionStyles,
  toastRootStyles,
  toastTitleStyles,
  toastViewportStyles,
} from "./Toast.css";

type ToastData = {
  index: number;
  title: string;
  isOpen: boolean;
  description: string;
};

type ToastProps = {
  setOpen: (index: number, open: boolean) => void;
} & ToastData;

export const Toast = ({
  index,
  isOpen,
  setOpen,
  title = "",
  description = "",
}: ToastProps) => (
  <RadixToast.Root
    className={toastRootStyles}
    open={isOpen}
    onOpenChange={(open) => setOpen(index, open)}
  >
    <RadixToast.Title className={toastTitleStyles}>{title}</RadixToast.Title>
    {description && (
      <RadixToast.Description asChild>
        <div className={toastDescriptionStyles}>{description}</div>
      </RadixToast.Description>
    )}
  </RadixToast.Root>
);

export const ToastViewport = () => (
  <RadixToast.Viewport className={toastViewportStyles} />
);

type setToastProps = {
  title: string;
  description: string;
};

type ToastContext = {
  showToast: (args: setToastProps) => void;
};

export const toastContext = React.createContext<ToastContext>({
  showToast: () => null,
});

export const useToast = () => React.useContext(toastContext);

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = React.useState<ToastData[]>([]);

  const handleShowToast = ({ title, description }: setToastProps) => {
    const newToast = {
      title,
      description,
      isOpen: true,
    };

    setToasts((prev) => [
      ...prev,
      { ...newToast, index: prev.length } as ToastData,
    ]);
  };

  const setOpen = (index: number, isOpen: boolean) => {
    setToasts((prev) => {
      const newToasts = [...prev];
      newToasts[index].isOpen = isOpen;
      return newToasts;
    });
  };

  return (
    <toastContext.Provider value={{ showToast: handleShowToast }}>
      <RadixToast.Provider>
        {children}
        {toasts.map((toast) => (
          <Toast
            key={toast.index}
            isOpen={toast.isOpen}
            setOpen={setOpen}
            title={toast.title}
            description={toast.description}
            index={toast.index}
          />
        ))}
        <ToastViewport />
      </RadixToast.Provider>
    </toastContext.Provider>
  );
};
