export const BitbucketSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.9087 3.00008C2.64368 2.99655 2.3907 3.11422 2.21764 3.32152C2.04458 3.52883 1.96915 3.80455 2.01158 4.07472L5.81987 27.9484C5.91782 28.5515 6.42093 28.9949 7.01305 28.9999H25.283C25.7274 29.0058 26.109 28.6748 26.1801 28.2217L29.9884 4.07935C30.0309 3.80918 29.9554 3.53346 29.7824 3.32615C29.6093 3.11885 29.3563 3.00118 29.0913 3.00471L2.9087 3.00008ZM18.9448 20.2546H13.1135L11.5346 11.7362H20.3578L18.9448 20.2546Z"
      fill="#2684FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.7774 11.7362H20.3579L18.9449 20.2546H13.1136L6.22815 28.6942C6.44639 28.8891 6.72463 28.9975 7.01313 29H25.2876C25.732 29.0059 26.1136 28.6748 26.1847 28.2218L28.7774 11.7362Z"
      fill="url(#paint0_linear_7021_35848)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7021_35848"
        x1="30.7242"
        y1="14.1217"
        x2="20.576"
        y2="28.0751"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stop-color="#0052CC" />
        <stop offset="1" stop-color="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
