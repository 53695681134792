import * as Tabs from "@radix-ui/react-tabs";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import {
  tabsContentStyles,
  tabsListStyles,
  tabsTriggerStyles,
} from "@/components/Tabs/Tabs.css";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import {
  usePackagesQuery,
  useRepositoryPreviewUpdatedSubscription,
} from "@/generated/graphql";
import { AppLayout } from "@/layouts/AppLayout/AppLayout";

import { EmptyTable } from "./EmptyTable/EmptyTable";
import { tabsRootStylesRepos } from "./Packages.css";
import { Package, Table } from "./PackagesTable";

export const Packages = () => {
  const navigate = useNavigate();
  const [{ data, fetching }] = usePackagesQuery();

  useRepositoryPreviewUpdatedSubscription();

  const handleRowClick = (p: Package, e: React.MouseEvent) => {
    const url = `/packages/${p.id}`;

    // TODO Handle middle mouse click
    // https://github.com/TanStack/table/discussions/2336#discussioncomment-2948209
    //
    // Handle Cmd + Click
    if (e.metaKey || e.ctrlKey) {
      return window.open(url, "_blank");
    }
    navigate(url);
  };

  const showEmptyTable = !data?.packages?.length;

  return (
    <>
      <Helmet>
        <title>Packages - DepsHub</title>
      </Helmet>
      <AppLayout.Header
        title="Packages"
        description="The list of all packages used in your repositories."
      />
      <Tabs.Root className={tabsRootStylesRepos} defaultValue="dependencies">
        <Tabs.List className={tabsListStyles}>
          <Tabs.Trigger className={tabsTriggerStyles} value="dependencies">
            My packages
          </Tabs.Trigger>
          <Tabs.Trigger
            className={tabsTriggerStyles}
            value="configuration"
            disabled
          >
            Explore
            <VersionPill version="Soon" />
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content className={tabsContentStyles} value="dependencies">
          {fetching ? null : showEmptyTable ? (
            <EmptyTable />
          ) : (
            <Table data={data} onRowClick={handleRowClick} />
          )}
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};
