import { Crisp } from "crisp-sdk-web";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as GraphqlProvider } from "urql";

import { DialogProvider } from "@/components/Dialog/DialogContext";
import { ToastProvider } from "@/components/Toast/Toast";

import { graphqlClient } from "./graphql";
import { AuthProvider } from "./providers/AuthProvider";
import { MainRouter } from "./routers";
import "./styles/code.css";
import "./styles/font.css.ts";
import "./styles/global.css.ts";
import "./styles/theme.css.ts";

const crispWebsiteId = "39b0da38-f50c-4d2d-8bd4-8154c270e67d";

const postHogOptions = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
};

const postHogApiKey = import.meta.env.PROD
  ? import.meta.env.VITE_POSTHOG_API_KEY
  : "";

function App() {
  useEffect(() => {
    Crisp.configure(crispWebsiteId, {
      autoload: false,
    });
  }, []);

  return (
    <PostHogProvider options={postHogOptions} apiKey={postHogApiKey}>
      <HelmetProvider>
        <AuthProvider>
          <ToastProvider>
            <GraphqlProvider value={graphqlClient}>
              <DialogProvider>
                <MainRouter />
              </DialogProvider>
            </GraphqlProvider>
          </ToastProvider>
        </AuthProvider>
      </HelmetProvider>
    </PostHogProvider>
  );
}

export default App;
