export const FileCodeSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 19.1533C5 19.6177 5 19.85 5.02567 20.0449C5.2029 21.3911 6.26222 22.4504 7.60842 22.6277C7.80337 22.6533 8.03558 22.6533 8.5 22.6533H16.2C17.8802 22.6533 18.7202 22.6533 19.362 22.3263C19.9265 22.0387 20.3854 21.5798 20.673 21.0153C21 20.3736 21 19.5335 21 17.8533V10.6415C21 9.90777 21 9.54089 20.9171 9.19562C20.8436 8.88952 20.7224 8.59688 20.5579 8.32847C20.3724 8.02572 20.113 7.76628 19.5941 7.24743L16.4059 4.05921C15.887 3.54035 15.6276 3.28093 15.3249 3.0954C15.0564 2.93091 14.7638 2.8097 14.4577 2.73621C14.1124 2.65332 13.7455 2.65332 13.0118 2.65332H8.5C8.03558 2.65332 7.80337 2.65332 7.60842 2.67899C6.26222 2.85622 5.2029 3.91554 5.02567 5.26174C5 5.45669 5 5.6889 5 6.15332M9 15.1533L11.5 12.6533L9 10.1533M5 10.1533L2.5 12.6533L5 15.1533"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
