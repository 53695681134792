import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { AppLayout } from "@/layouts/AppLayout/AppLayout";
import { FullScreenLayout } from "@/layouts/FullScreenLayout/FullScreenLayout";
import { Dashboard } from "@/pages/Dashboard/Dashboard";
import { License } from "@/pages/License/License";
import { Licenses } from "@/pages/Licenses/Licenses";
import { Onboarding } from "@/pages/Onboarding/Onboarding";
import { Package } from "@/pages/Package/Package";
import { Packages } from "@/pages/Packages/Packages";
import { Repositories } from "@/pages/Repositories/Repositories";
import { Repository } from "@/pages/Repository/Repository";
import { Settings } from "@/pages/Settings/Settings";

export const loggedInRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AppLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/repositories/:repositoryId" element={<Repository />} />
        <Route path="/repositories" element={<Repositories />} />
        <Route path="/licenses" element={<Licenses />} />
        <Route path="/licenses/:name" element={<License />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/packages/:id" element={<Package />} />
        <Route path="/settings/:tab?" element={<Settings />} />
      </Route>
      <Route element={<FullScreenLayout />}>
        <Route path="/onboarding" element={<Onboarding />} />
      </Route>
      <Route path="*" element={<Navigate to="/repositories" />} />
    </>
  )
);
