import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { sentryInit } from "./sentry";

window.GIT_SHA = import.meta.env.VITE_GIT_SHA;

sentryInit();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
