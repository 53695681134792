export const GithubSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 0.5C5.19875 0.5 0.5 5.19875 0.5 11C0.5 15.6462 3.50562 19.5706 7.67937 20.9619C8.20437 21.0538 8.40125 20.7388 8.40125 20.4631C8.40125 20.2138 8.38813 19.3869 8.38813 18.5075C5.75 18.9931 5.0675 17.8644 4.8575 17.2737C4.73937 16.9719 4.2275 16.04 3.78125 15.7906C3.41375 15.5937 2.88875 15.1081 3.76813 15.095C4.595 15.0819 5.18563 15.8562 5.3825 16.1712C6.3275 17.7594 7.83688 17.3131 8.44063 17.0375C8.5325 16.355 8.80812 15.8956 9.11 15.6331C6.77375 15.3706 4.3325 14.465 4.3325 10.4487C4.3325 9.30687 4.73938 8.36187 5.40875 7.62687C5.30375 7.36437 4.93625 6.28812 5.51375 4.84437C5.51375 4.84437 6.39313 4.56875 8.40125 5.92063C9.24125 5.68438 10.1338 5.56625 11.0263 5.56625C11.9188 5.56625 12.8113 5.68438 13.6513 5.92063C15.6594 4.55563 16.5388 4.84437 16.5388 4.84437C17.1163 6.28812 16.7488 7.36437 16.6438 7.62687C17.3131 8.36187 17.72 9.29375 17.72 10.4487C17.72 14.4781 15.2656 15.3706 12.9294 15.6331C13.31 15.9612 13.6381 16.5913 13.6381 17.5756C13.6381 18.98 13.625 20.1088 13.625 20.4631C13.625 20.7388 13.8219 21.0669 14.3469 20.9619C16.4313 20.2582 18.2426 18.9185 19.5258 17.1315C20.809 15.3445 21.4994 13.2 21.5 11C21.5 5.19875 16.8013 0.5 11 0.5Z"
      fill="currentColor"
    />
  </svg>
);
