import { useRef, useState } from "react";

import { Button } from "@/components/Button/Button";
import { Input } from "@/components/Input/Input";
import { isValidEmail } from "@/utils";

import { Icon } from "../Icon/Icon";
import {
  buttonsWrapperStyles,
  emailStyles,
  emailWrapperStyles,
  formStyles,
} from "./InviteEmails.css";

type InviteEmailsProps = {
  onInvite?: (emails: string[]) => void;
};

export const InviteEmails = ({ onInvite }: InviteEmailsProps) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [pendingEmail, setPendingEmail] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPendingEmail(e.target.value);
  };

  const handleEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddEmail();
    }
  };

  const handleAddEmail = () => {
    setEmails((prev) => [...prev, pendingEmail]);
    setPendingEmail("");
    inputRef.current?.focus();
  };

  const handleRemoveEmail = (email: string) => {
    setEmails((prev) => prev.filter((e) => e !== email));
  };

  const handleSendInvite = () => {
    const emailsToSend = [
      ...new Set([...emails, pendingEmail].filter(Boolean)),
    ];

    onInvite && onInvite(emailsToSend);
  };

  const isSendInviteActive = emails.length > 0 || isValidEmail(pendingEmail);
  return (
    <>
      <div className={formStyles}>
        <Input
          placeholder="Enter email address"
          onChange={handleEmailChange}
          onKeyDown={handleEmailKeyDown}
          value={pendingEmail}
          autoFocus
          ref={inputRef}
        />
        <div className={buttonsWrapperStyles}>
          <Button
            onClick={handleAddEmail}
            disabled={!isValidEmail(pendingEmail)}
            size="small"
            icon={<Icon.Plus />}
            hierarchy="secondary"
          >
            Add more
          </Button>
          <Button
            hierarchy="primary"
            disabled={!isSendInviteActive}
            size="small"
            icon={<Icon.Email />}
            onClick={handleSendInvite}
          >
            Invite
          </Button>
        </div>
      </div>
      {emails.length > 0 && (
        <div className={emailWrapperStyles}>
          {emails.map((email) => (
            <div key={email} className={emailStyles}>
              <span>{email}</span>
              <Button
                hierarchy="secondary"
                size="small"
                onClick={() => handleRemoveEmail(email)}
              >
                Remove
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
