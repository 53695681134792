import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import yaml from "react-syntax-highlighter/dist/esm/languages/hljs/yaml";
import theme from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

import { RepositorySettings } from "@/generated/graphql";

import {
  codeDescriptoinStyles,
  codeSpan,
  codeStyles,
} from "./Configuration.css";

SyntaxHighlighter.registerLanguage("yaml", yaml);

type ConfigurationProps = {
  settings?: RepositorySettings;
};

export const Configuration = ({ settings }: ConfigurationProps) => {
  return (
    <>
      {!settings?.isDefault ? (
        <div className={codeDescriptoinStyles}>
          We detected a <span className={codeSpan}>depshub.yaml</span> file for
          this repository. Learn more in the <a href="#">documentation</a>.
        </div>
      ) : (
        <div className={codeDescriptoinStyles}>
          We didn't detect a <span className={codeSpan}>depshub.yaml</span> file
          for this repository. Showing the default settings instead. Learn more
          in the <a href="#">documentation</a>.
        </div>
      )}
      <SyntaxHighlighter
        language="yaml"
        style={theme}
        showLineNumbers
        className={codeStyles}
      >
        {settings?.raw ?? ""}
      </SyntaxHighlighter>
    </>
  );
};
