import { DialogProps } from "@radix-ui/react-dialog";

import { Dialog } from "@/components/Dialog/Dialog";
import { Icon } from "@/components/Icon/Icon";
import { InviteEmails } from "@/components/InviteEmails/InviteEmails";

import {
  useInviteUsersToWorkspaceMutation,
  useRootQueryQuery,
} from "../../../generated/graphql";
import { contentStyles, workspaceNameStyles } from "./InviteMembers.css";

export type InviteMembersProps = DialogProps;

export const InviteMembers = ({
  onOpenChange,
  ...rest
}: InviteMembersProps) => {
  const [{ data }] = useRootQueryQuery();
  const workspace = data?.workspace;
  const [, inviteUsersToWorkspace] = useInviteUsersToWorkspaceMutation();

  const handleInvite = (emails: string[]) => {
    inviteUsersToWorkspace({ emails });
    onOpenChange && onOpenChange(false);
  };

  return (
    <Dialog.Root {...rest} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className={contentStyles}>
          <Dialog.Title>
            Inivite members to{" "}
            <b className={workspaceNameStyles}>{workspace?.name}</b>
          </Dialog.Title>
          <Dialog.Description>
            Enter the email addresses of the people you want to invite to your
            workspace.
          </Dialog.Description>
          <Dialog.CloseIcon asChild>
            <Icon.Close />
          </Dialog.CloseIcon>
          <InviteEmails onInvite={handleInvite} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
