import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import { Icon } from "@/components/Icon/Icon";
import { Markdown } from "@/components/Markdown/Markdown";
import { useToast } from "@/components/Toast/Toast";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import {
  UpdatePackagesInput,
  Vulnerability as VulnerabilityType,
  useUpdatePackagesMutation,
} from "@/generated/graphql";

import {
  aliasesWrapperStyles,
  cardPointStyles,
  cardStyles,
  cardSubtitleStyles,
  contentStyles,
  detailsStyles,
  infoPanelStyles,
  referenceUrlWrapperStyles,
  solutionStyles,
  truncatedPillStyles,
  wrapperStyles,
} from "./Vulnerability.css";
import {
  formatAvailability,
  formatComplexity,
  formatIntegrity,
  formatPrivileges,
  formatPublished,
} from "./utils";

type VulnerabilityProps = {
  vulnerability: VulnerabilityType;
};

export const Vulnerability = ({ vulnerability }: VulnerabilityProps) => {
  const [, updatePackagesMutation] = useUpdatePackagesMutation();
  const { showToast } = useToast();
  const patchedVersion = vulnerability.occurrence.patchedVersion;

  const handleFix = () => {
    showToast({
      title: `Updating ${vulnerability.name} package to version ${patchedVersion}`,
      description: "This may take up to a minute.",
    });

    updatePackagesMutation({
      packages: [
        {
          manifestPackageId: vulnerability.occurrence.manifestPackageId,
          packageVersionId: vulnerability.occurrence.patchedVersionId,
        } as UpdatePackagesInput,
      ],
    });
  };

  return (
    <div className={wrapperStyles}>
      <div className={contentStyles}>
        {patchedVersion && (
          <div className={solutionStyles}>
            Upgrade {vulnerability.name} to {patchedVersion} to fix this
            vulnerability.
            <Button icon={<Icon.Update />} size="small" onClick={handleFix}>
              Fix
            </Button>
          </div>
        )}
        <div className={aliasesWrapperStyles}>
          {vulnerability.aliases.map((alias) => (
            <VersionPill version={alias} key={alias} />
          ))}
        </div>
        <div className={detailsStyles}>
          <Markdown>{vulnerability.details ?? ""}</Markdown>
        </div>
      </div>
      <div className={infoPanelStyles}>
        <Card>
          <div className={cardStyles}>
            {vulnerability.published && (
              <div className={cardPointStyles}>
                <div className={cardSubtitleStyles}>Introduced</div>
                <VersionPill
                  version={formatPublished(vulnerability.published)}
                />
              </div>
            )}
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Attack complexity</div>
              <VersionPill version={formatComplexity(vulnerability.severity)} />
            </div>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Integrity</div>
              <VersionPill version={formatIntegrity(vulnerability.severity)} />
            </div>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Availability</div>
              <VersionPill
                version={formatAvailability(vulnerability.severity)}
              />
            </div>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Privileges required</div>
              <VersionPill version={formatPrivileges(vulnerability.severity)} />
            </div>
          </div>
        </Card>
        <Card>
          <div className={cardStyles}>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>References</div>
              {vulnerability.references.map((reference) => (
                <a
                  href={reference.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={referenceUrlWrapperStyles}
                  key={reference.url}
                >
                  <VersionPill
                    version={reference.url}
                    className={truncatedPillStyles}
                  />
                </a>
              ))}
            </div>
            <div className={cardPointStyles}>
              <div className={cardSubtitleStyles}>Credits</div>
              {vulnerability.credits.map((credit) => (
                <VersionPill
                  version={credit.name ?? credit.contact[0] ?? ""}
                  className={truncatedPillStyles}
                  key={credit.name}
                />
              ))}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
