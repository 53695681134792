import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import { formatDistanceStrict } from "date-fns";
import React from "react";

import {
  Dropdown,
  dropdownMenuContentStyles,
  dropdownMenuItemStyles,
} from "@/components/Dropdown/Dropdown";
import {
  dropdownMenuItemIconStyles,
  dropdownMenuRightSlotStyles,
  recommendedVersionStyles,
} from "@/components/Dropdown/Dropdown.css";

import { Icon } from "../Icon/Icon";
import {
  badgeHoverStyles,
  badgeStyles,
  iconStyles,
  indicatorStyles,
  selectorIconStyles,
} from "./VersionPill.css";

export type Version = {
  version: string;
  releasedAt?: string;
  id?: string;
};

type VersionPill = {
  version: string;
  recommendedVersion?: string;
  dimmed?: boolean;
  type?: "success" | "warning" | "error";
  className?: string;
  icon?: React.ReactNode;
  versions?: Version[];
  onVersionChange?: (versionId: string) => void;
};

export const VersionPill = ({
  version,
  recommendedVersion,
  onVersionChange,
  type,
  dimmed,
  className,
  icon,
  versions = [],
}: VersionPill) => {
  const hasVersions = !!(versions && versions.length > 1);

  const handleValueChange = (value: string) => {
    if (onVersionChange) {
      onVersionChange(value);
    }
  };

  const dropdownContent = (
    <DropdownMenu.Content className={dropdownMenuContentStyles} sideOffset={5}>
      <DropdownMenu.RadioGroup
        value={version}
        onValueChange={handleValueChange}
      >
        {versions.map((v) => (
          <DropdownMenu.RadioItem
            key={v.id}
            className={dropdownMenuItemStyles}
            value={v.version}
          >
            <DropdownMenu.ItemIndicator className={dropdownMenuItemIconStyles}>
              <Icon.Check />
            </DropdownMenu.ItemIndicator>
            {v.version}
            {recommendedVersion && recommendedVersion.includes(v.version) && (
              <div
                className={classNames(
                  dropdownMenuRightSlotStyles,
                  recommendedVersionStyles
                )}
              >
                Recommended
              </div>
            )}
            {v.releasedAt && (
              <div className={dropdownMenuRightSlotStyles}>
                {formatDistanceStrict(v.releasedAt, new Date(), {
                  addSuffix: true,
                })}
              </div>
            )}
          </DropdownMenu.RadioItem>
        ))}
      </DropdownMenu.RadioGroup>
    </DropdownMenu.Content>
  );

  return (
    <Dropdown content={hasVersions ? dropdownContent : null}>
      <span
        className={classNames(
          dimmed ? badgeStyles.Dimmed : badgeStyles.Default,
          versions && !dimmed && badgeHoverStyles,
          className
        )}
      >
        {type && (
          <div
            className={
              type === "success"
                ? indicatorStyles.Success
                : type === "warning"
                ? indicatorStyles.Warning
                : type === "error"
                ? indicatorStyles.Error
                : ""
            }
          />
        )}
        {icon && <span className={iconStyles}>{icon}</span>}
        <span>{version}</span>
        {hasVersions && <Icon.ChevronRight className={selectorIconStyles} />}
      </span>
    </Dropdown>
  );
};
