import { Helmet } from "react-helmet-async";

import { Separator } from "@/components/Separator/Separator";
import { useLicensesQuery } from "@/generated/graphql";
import { AppLayout } from "@/layouts/AppLayout/AppLayout";

import { EmptyTable } from "./EmptyTable/EmptyTable";
import { LicensesTable } from "./LicensesTable";

export const Licenses = () => {
  const [{ data: licensesData, fetching }] = useLicensesQuery();

  const noData = !licensesData?.licenses?.length && !fetching;

  return (
    <>
      <Helmet>
        <title>Licenses - DepsHub</title>
      </Helmet>
      <AppLayout.Header
        title="Licenses"
        description="List of licenses used in your repositories."
      />
      <Separator />
      {noData ? <EmptyTable /> : <LicensesTable data={licensesData} />}
    </>
  );
};
