import { Icon } from "../Icon/Icon";
import { updatedTooltipTextStyles } from "./StatusTooltip.css";

type StatusTooltipProps = {
  updated: number;
  outdated: number;
  critical: number;
  security: number;
};

export const StatusTooltip = ({
  updated,
  outdated,
  critical,
  security,
}: StatusTooltipProps) => {
  const total = updated + outdated + critical + security;

  const updatedPercentage = (updated / total) * 100;
  const outdatedPercentage = (outdated / total) * 100;
  const criticalPercentage = (critical / total) * 100;
  const securityPercentage = (security / total) * 100;

  const tooltipContent = (
    <>
      {updatedPercentage ? (
        <span className={updatedTooltipTextStyles.Success}>
          <Icon.Check />
          Updated: {Math.round(updatedPercentage)}%
        </span>
      ) : null}
      {outdatedPercentage ? (
        <span className={updatedTooltipTextStyles.Warning}>
          <Icon.AlertTriangle />
          Minor updates: {Math.round(outdatedPercentage)}%
        </span>
      ) : null}
      {criticalPercentage ? (
        <span className={updatedTooltipTextStyles.Error}>
          <Icon.AlertTriangle />
          Major updates: {Math.round(criticalPercentage)}%
        </span>
      ) : null}
      {securityPercentage ? (
        <span className={updatedTooltipTextStyles.Security}>
          <Icon.AlertOctagon />
          Security updates: {Math.round(securityPercentage)}%
        </span>
      ) : null}
    </>
  );

  return tooltipContent;
};
