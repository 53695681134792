export const GitlabSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.0084 29.665L21.5284 12.6763H10.4884L16.0084 29.665Z"
      fill="#E24329"
    />
    <path
      d="M16.0087 29.665L10.4887 12.6763H2.75253L16.0087 29.665Z"
      fill="#FC6D26"
    />
    <path
      d="M2.75231 12.6763L1.07488 17.839C0.921879 18.3098 1.08945 18.8257 1.49002 19.1167L16.0084 29.665L2.75231 12.6763Z"
      fill="#FCA326"
    />
    <path
      d="M2.75262 12.6763H10.4888L7.16405 2.44413C6.99305 1.91756 6.2482 1.9177 6.0772 2.44413L2.75262 12.6763Z"
      fill="#E24329"
    />
    <path
      d="M16.0095 29.665L21.5295 12.6763H29.2656L16.0095 29.665Z"
      fill="#FC6D26"
    />
    <path
      d="M29.2656 12.6763L30.943 17.839C31.096 18.3098 30.9285 18.8257 30.5279 19.1167L16.0095 29.665L29.2656 12.6763Z"
      fill="#FCA326"
    />
    <path
      d="M29.2651 12.6763H21.529L24.8537 2.44413C25.0247 1.91756 25.7695 1.9177 25.9405 2.44413L29.2651 12.6763Z"
      fill="#E24329"
    />
  </svg>
);
