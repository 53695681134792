import classNames from "classnames";

import {
  contentStyles,
  descriptionStyles,
  dimmedStyles,
  titleStyles,
  wrapperStyles,
} from "./Card.css";

type CardProps = {
  children: React.ReactNode;
  className?: string;
  title?: string;
  description?: string;
  dimmed?: boolean;
};

export const Card = ({
  children,
  title,
  className,
  description,
  dimmed,
}: CardProps) => (
  <div className={classNames(wrapperStyles, className, dimmed && dimmedStyles)}>
    {title && <div className={titleStyles}>{title}</div>}
    {description && <div className={descriptionStyles}>{description}</div>}
    <div className={contentStyles}>{children}</div>
  </div>
);
