import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { Icon } from "@/components/Icon/Icon";
import { Separator } from "@/components/Separator/Separator";
import {
  Organization,
  RepositoryPreview,
  useInitializeRepositoryMutation,
  useOrganizationsQuery,
  useRepositoryPreviewUpdatedSubscription,
} from "@/generated/graphql";
import { AppLayout } from "@/layouts/AppLayout/AppLayout";
import { connectGithubRepository } from "@/utils";

import { EmptyTable } from "./EmptyTable/EmptyTable";
import { orgImgStyles, organizationStyles } from "./Repositories.css";
import { Table } from "./RepositoriesTable";

const columnHelper = createColumnHelper<Organization>();

const columns = [
  columnHelper.accessor("name", {
    header: () => <>Repository</>,
    cell: (info) => (
      <span className={organizationStyles}>
        <img
          src={info.row.original.profileImgHref}
          alt={info.row.original.name}
          className={orgImgStyles}
        />
        {info.getValue()}
      </span>
    ),
    maxSize: 100,
    size: 100,
    enableGrouping: true,
  }),
  columnHelper.accessor("profileImgHref", {
    header: () => <>Automatic updates</>,
    cell: () => null,
  }),
  columnHelper.accessor("id", {
    header: () => <>Status</>,
    cell: () => null,
  }),
];

export const Repositories = () => {
  const navigate = useNavigate();
  const [{ data, fetching }] = useOrganizationsQuery();
  const [, initializeRepositoryMutation] = useInitializeRepositoryMutation();

  useRepositoryPreviewUpdatedSubscription();

  const table = useReactTable({
    data: data?.organizations || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false,
  });

  const handleRowClick = (repo: RepositoryPreview, e: React.MouseEvent) => {
    const url = `/repositories/${repo.id}`;

    // TODO Handle middle mouse click
    // https://github.com/TanStack/table/discussions/2336#discussioncomment-2948209
    //
    // Handle Cmd + Click
    if (e.metaKey || e.ctrlKey) {
      return window.open(url, "_blank");
    }
    navigate(url);
  };

  const handleRepositoryConnect = (repo: RepositoryPreview) => {
    initializeRepositoryMutation({
      id: repo.id,
    });
  };

  const showEmptyTable = !data?.organizations?.length;

  return (
    <>
      <Helmet>
        <title>Repositories - DepsHub</title>
      </Helmet>
      <AppLayout.Header
        title="Repositories"
        description="Select a repository to check its current status."
        action={
          !showEmptyTable ? (
            <Button
              onClick={connectGithubRepository}
              children="Manage repositories"
              icon={<Icon.Settings />}
            />
          ) : undefined
        }
      />
      <Separator />
      {fetching ? null : showEmptyTable ? (
        <EmptyTable />
      ) : (
        <Table
          table={table}
          onRowClick={handleRowClick}
          onRepositoryConnect={handleRepositoryConnect}
        />
      )}
    </>
  );
};
