import {
  ExpandedState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { Fragment } from "react";

import {
  expandedTrStyles,
  tdStyles,
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import {
  RepositoryQuery,
  Vulnerability as VulnerabilityType,
  useVulnerabilitiesQuery,
} from "@/generated/graphql";
import { getManifestTypeImg } from "@/utils";

import {
  manifestNameStyles,
  manifestNameWrapperStyles,
  manifestTotalStyles,
} from "../Dependencies/Dependencies.css";
import { emptyStateStyles, pathStyles, versionStyles } from "./Security.css";
import { Vulnerability } from "./Vulnerability";

type SecurityColumn = VulnerabilityType;

const columnHelper = createColumnHelper<SecurityColumn>();

const columns = [
  columnHelper.accessor("name", {
    header: () => <>Vulnerability</>,
    cell: ({ getValue, row }) => (
      <div>
        <span className={manifestNameWrapperStyles}>
          <img
            src={getManifestTypeImg(row.original.occurrence?.manifestType)}
          />
          <div>
            <div className={manifestNameStyles}>
              {getValue()}
              <span className={pathStyles}>
                ({row.original.occurrence.path})
              </span>
            </div>
            <div className={manifestTotalStyles}>{row.original.summary}</div>
          </div>
        </span>
      </div>
    ),
    size: 450,
  }),
  columnHelper.accessor("occurrence.version", {
    header: () => <>Vulnerable version</>,
    cell: ({ getValue }) => (
      <div className={versionStyles}>
        <VersionPill version={getValue()} />
      </div>
    ),
  }),
  columnHelper.accessor("occurrence.patchedVersion", {
    header: () => <>Patched version</>,
    cell: ({ getValue }) => (
      <div className={versionStyles}>
        <VersionPill version={getValue()} />
      </div>
    ),
  }),
  columnHelper.accessor("severity", {
    header: () => <>Score</>,
    cell: ({ getValue }) => {
      let type = "success" as "success" | "warning" | "error";
      const values = getValue();
      const value = values[0].score ?? 0;

      if (value >= 4) type = "success";
      if (value >= 6) type = "warning";
      if (value >= 8) type = "error";

      return (
        <div className={versionStyles}>
          <VersionPill type={type} version={String(value || "Unknown")} />
        </div>
      );
    },
  }),
];

type SecurityProps = {
  data?: RepositoryQuery;
};

export const Security = ({ data }: SecurityProps) => {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [{ data: vulnerabilitiesResult }] = useVulnerabilitiesQuery({
    variables: {
      id: data?.repository.id ?? "",
    },
  });

  const table = useReactTable({
    data: vulnerabilitiesResult?.repository.vulnerabilities || [],
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugAll: false,
  });

  if (data?.repository?.status.security === 0) {
    return (
      <div className={emptyStateStyles}>
        No vulnerabilities found for dependencies in this repository.
      </div>
    );
  }

  return (
    <>
      <table className={wrapperStyles}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className={thStyles}
                  key={header.id}
                  style={{
                    width: header.column.columnDef.size,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <tr
                className={row.getIsExpanded() ? expandedTrStyles : trStyles}
                onClick={() => {
                  row.toggleExpanded();
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={tdStyles}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {row.getIsExpanded() && (
                <tr>
                  <td colSpan={row.getVisibleCells().length}>
                    <Vulnerability vulnerability={row.original} />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
};
