import { forwardRef } from "react";

import { iconStyles, inputStyles, wrapperStyles } from "./Input.css";

type InputProps = {
  icon?: React.ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ icon, className, ...rest }, ref) => (
    <div className={wrapperStyles}>
      <input
        {...rest}
        ref={ref}
        className={`${icon ? inputStyles.WithIcon : inputStyles.Default} ${
          className ?? ""
        }`}
      />
      {icon && <div className={iconStyles}>{icon}</div>}
    </div>
  )
);
