import { Outlet } from "react-router-dom";

import {
  demoStyles,
  leftSideContainerStyles,
  linePatternStyles1,
  linePatternStyles2,
  rightSideContainerStyles,
  wrapperStyles,
} from "./AuthLayout.css";

export const AuthLayout = () => {
  return (
    <div className={wrapperStyles}>
      <div className={leftSideContainerStyles}>
        <Outlet />
      </div>
      <div className={rightSideContainerStyles}>
        <img className={linePatternStyles1} src="img/line-pattern.svg" />
        <img className={linePatternStyles2} src="img/line-pattern.svg" />
        <img className={demoStyles} src="img/dashboard-demo.png" />
      </div>
    </div>
  );
};
