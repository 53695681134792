import * as AvatarRadix from "@radix-ui/react-avatar";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { useEffect, useRef } from "react";

import { fallbackStyles, imageStyles, rootStyles, sizeVar } from "./Avatar.css";

type AvatarProps = {
  size?: number;
  src: string;
  fallback: string;
};

export const Avatar = ({ src, fallback, size = 32 }: AvatarProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  // There is an open issue with referrerPolicy not being applied to the img element
  // https://github.com/radix-ui/primitives/issues/1616
  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.setAttribute("referrerpolicy", "no-referrer");
    }
  }, [imgRef]);

  return (
    <AvatarRadix.Root
      className={rootStyles}
      style={assignInlineVars({
        [sizeVar]: `${size}px`,
      })}
    >
      <AvatarRadix.Image
        className={imageStyles}
        src={src}
        alt="user avatar"
        referrerPolicy="no-referrer"
        ref={imgRef}
      />
      <AvatarRadix.Fallback className={fallbackStyles} delayMs={600}>
        {fallback}
      </AvatarRadix.Fallback>
    </AvatarRadix.Root>
  );
};
