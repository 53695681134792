import {
  Combobox as ComboboxAriakit,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider,
} from "@ariakit/react";
import * as RadixSelect from "@radix-ui/react-select";
import { matchSorter } from "match-sorter";
import { startTransition, useEffect, useMemo, useState } from "react";

import { Icon } from "@/components/Icon/Icon";

import {
  clearIconStyles,
  clearTextStyles,
  comboboxIconStyles,
  comboboxStyles,
  comboboxWrapperStyles,
  dropdownIconStyles,
  iconStyles,
  itemIndicatorStyles,
  itemStyles,
  listboxStyles,
  popoverStyles,
  selectStyles,
} from "./Combobox.css";

export type ComboboxValue = string | number;

type ComboboxOption = {
  label: string;
  value: ComboboxValue;
};

type ComboboxProps = {
  values: ComboboxOption[];
  icon?: React.ReactNode;
  placeholder: string;
  onChange?: (value: ComboboxValue) => void;
};

export default function Combobox({
  values,
  placeholder,
  icon,
  onChange,
}: ComboboxProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const matches = useMemo(() => {
    if (!searchValue) return values;
    const keys = ["label", "value"];
    const matches = matchSorter(values, searchValue, { keys });
    // Radix Select does not work if we don't render the selected item, so we
    // make sure to include it in the list of matches.
    const selectedLanguage = values.find((lang) => lang.value === value);
    if (selectedLanguage && !matches.includes(selectedLanguage)) {
      matches.push(selectedLanguage);
    }
    return matches;
  }, [searchValue, value, values]);

  useEffect(() => {
    if (value === "__clear") {
      setValue("");
      onChange?.("");
      return;
    }

    value && onChange?.(value);
  }, [value, onChange]);

  return (
    <RadixSelect.Root
      value={value}
      onValueChange={setValue}
      open={open}
      onOpenChange={setOpen}
    >
      <ComboboxProvider
        open={open}
        setOpen={setOpen}
        resetValueOnHide
        includesBaseElement={false}
        setValue={(value) => {
          startTransition(() => {
            setSearchValue(value);
          });
        }}
      >
        <RadixSelect.Trigger aria-label="values" className={selectStyles}>
          {icon && <div className={iconStyles}>{icon}</div>}
          <RadixSelect.Value placeholder={placeholder} />
          <RadixSelect.Icon className={dropdownIconStyles}>
            <Icon.ChevronRight />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Content
          role="dialog"
          aria-label="Values"
          position="popper"
          className={popoverStyles}
          sideOffset={4}
          alignOffset={-16}
        >
          <div className={comboboxWrapperStyles}>
            <div className={comboboxIconStyles}>
              <Icon.Search />
            </div>
            <ComboboxAriakit
              autoSelect
              placeholder={placeholder}
              className={comboboxStyles}
              // Ariakit's Combobox manually triggers a blur event on virtually
              // blurred items, making them work as if they had actual DOM
              // focus. These blur events might happen after the corresponding
              // focus events in the capture phase, leading Radix Select to
              // close the popover. This happens because Radix Select relies on
              // the order of these captured events to discern if the focus was
              // outside the element. Since we don't have access to the
              // onInteractOutside prop in the Radix SelectContent component to
              // stop this behavior, we can turn off Ariakit's behavior here.
              onBlurCapture={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          </div>
          <ComboboxList className={listboxStyles}>
            {value && (
              <RadixSelect.Item
                key="__clear"
                value="__clear"
                asChild
                className={itemStyles}
              >
                <ComboboxItem>
                  <Icon.Close className={clearIconStyles} />
                  <RadixSelect.ItemText>
                    <span className={clearTextStyles}>Clear</span>
                  </RadixSelect.ItemText>
                </ComboboxItem>
              </RadixSelect.Item>
            )}
            {matches.map(({ label, value }) => (
              <RadixSelect.Item
                key={value}
                value={String(value)}
                asChild
                className={itemStyles}
              >
                <ComboboxItem>
                  <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                  <RadixSelect.ItemIndicator className={itemIndicatorStyles}>
                    <Icon.Check />
                  </RadixSelect.ItemIndicator>
                </ComboboxItem>
              </RadixSelect.Item>
            ))}
          </ComboboxList>
        </RadixSelect.Content>
      </ComboboxProvider>
    </RadixSelect.Root>
  );
}
