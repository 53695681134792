import {
  ExpandedState,
  Row,
  RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

import { Button } from "@/components/Button/Button";
import { Checkbox } from "@/components/Checkbox/Checkbox";
import { Dialog, useDialogContext } from "@/components/Dialog/DialogContext";
import { PackageHoverCard } from "@/components/HoverCard/Package/Package";
import { Icon } from "@/components/Icon/Icon";
import { StatusBar } from "@/components/StatusBar/StatusBar";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { Version, VersionPill } from "@/components/VersionPill/VersionPill";
import {
  DataSource,
  DependencyState,
  DependencyUpgradeType,
  PullRequest,
  RepositoryQuery,
} from "@/generated/graphql";
import { vars } from "@/styles/theme.css";

import {
  nameStyles,
  tdStyles,
  trStyles,
  wrapperStyles,
} from "./DependenciesTable.css";

export type DependencyRow = {
  id: string;
  manifestPackageId: string;
  name: string;
  rawVersion: string;
  latestVersion?: string;
  selectedVersion?: string;
  selectedVersionId?: string;
  versions?: Version[];
  updateType?: DependencyUpgradeType;
  changelog?: string[];
  state: DependencyState;
  pullRequest?: PullRequest;
  dataSource: DataSource;
  description: string;
  scoreTotal: number;
  releasedAt: string;
};

const depsColumnHelper = createColumnHelper<DependencyRow>();

type Dependencies =
  RepositoryQuery["repository"]["manifestFiles"][0]["dependencies"];

type DependenciesTableProps = {
  dependencies: Dependencies;
  setSelectedPackage: (packageVersion: DependencyRow) => void;
  rowSelection?: RowSelectionState;
  setRowSelection?: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export function DependenciesTable({
  dependencies,
  setSelectedPackage,
  rowSelection,
  setRowSelection,
}: DependenciesTableProps) {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const { openDialog } = useDialogContext();

  const isUpdateAllowed = (row: Row<DependencyRow>) => {
    return (
      row.original.updateType &&
      row.original.state !== DependencyState.Updating &&
      !row.original.pullRequest?.url
    );
  };

  const handlePackageToggle = (index: number) => {
    const row = table.getRowModel().rows[index];
    const isSelected = row.getIsSelected();

    if (!isUpdateAllowed(row)) {
      return;
    }

    row.toggleSelected(!isSelected);
    setSelectedPackage(row.original);
  };

  const handleHandlePreview = (
    e: React.MouseEvent<HTMLButtonElement>,
    row: DependencyRow
  ) => {
    e.stopPropagation();
    e.preventDefault();

    openDialog(Dialog.PreviewUpdates, {
      title: "Preview Updates",
      description: (
        <div>
          <p>
            You are about to update <strong>{row.name}</strong> from{" "}
            <strong>{row.rawVersion}</strong> to{" "}
            <strong>{row.latestVersion}</strong>.
          </p>
        </div>
      ),
      content: row.changelog?.join(" "),
    });
  };

  const renderPreview = (row: Row<DependencyRow>) => {
    let content = null;

    if (row.original.state === DependencyState.Updating) {
      content = (
        <Tooltip content="We're updating this package. Please, wait.">
          <Button
            hierarchy="secondary"
            size="small"
            disabled
            icon={<Icon.Github />}
          >
            Opening PR
          </Button>
        </Tooltip>
      );
    } else if (row.original.pullRequest?.url) {
      content = (
        <Button
          hierarchy="secondary"
          size="small"
          icon={<Icon.Github />}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.open(row.original.pullRequest?.url, "_blank");
          }}
          style={{ border: `1px solid ${vars.color.primary8}` }}
        >
          View PR
        </Button>
      );
    } else if (
      row.original.updateType &&
      (row.original.changelog?.length ?? 0) > 0
    ) {
      content = (
        <Button
          hierarchy="secondary"
          size="small"
          onClick={(e) => handleHandlePreview(e, row.original)}
        >
          Preview
        </Button>
      );
    }

    return (
      // we need to use a fixed width to prevent the cell from growing
      // when changing the button text
      <span style={{ display: "flex", width: 200 }}>{content}</span>
    );
  };

  const depsColumns = [
    depsColumnHelper.accessor("name", {
      header: () => <>Package name</>,
      cell: ({ getValue, row }) => (
        <span className={nameStyles}>
          {isUpdateAllowed(row) && <Checkbox checked={row.getIsSelected()} />}
          <PackageHoverCard
            id={row.original.id}
            name={getValue()}
            score={row.original.scoreTotal}
            description={row.original.description}
            dataSource={row.original.dataSource}
          />
        </span>
      ),
      maxSize: 100,
      size: 100,
    }),
    depsColumnHelper.accessor("rawVersion", {
      header: () => <>Status</>,
      cell: ({ getValue, row }) => {
        let content = null;
        const handleVersionChange = (versionStr: string) => {
          const version = row.original.versions?.find(
            (v) => v.version === versionStr
          );

          row.original.selectedVersion = version?.version;
          row.original.selectedVersionId = version?.id;
        };

        const versions =
          row.original.pullRequest || !row.original.versions
            ? []
            : row.original.versions.map((version) => ({
                version: version.version,
                id: version.id,
                releasedAt: version.releasedAt,
              }));

        if (row.original.state === DependencyState.Updating) {
          content = <StatusBar isLoading />;
        } else {
          content = (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <VersionPill
                version={getValue()}
                dimmed={!!row.original.updateType}
                type={
                  row.original.updateType === DependencyUpgradeType.Security
                    ? "error"
                    : undefined
                }
              />
              {row.original.updateType && (
                <>
                  <Icon.ArrowRight
                    style={{
                      color:
                        row.original.updateType ===
                        DependencyUpgradeType.Security
                          ? vars.color.error9
                          : row.original.updateType ===
                            DependencyUpgradeType.Major
                          ? vars.color.warning9
                          : row.original.updateType ===
                            DependencyUpgradeType.Minor
                          ? vars.color.success7
                          : vars.color.success9,
                    }}
                  />
                  <VersionPill
                    version={row.original.selectedVersion ?? ""}
                    recommendedVersion={row.original.latestVersion}
                    onVersionChange={handleVersionChange}
                    versions={versions}
                  />
                </>
              )}
            </div>
          );
        }

        return <div style={{ width: 300, display: "flex" }}>{content}</div>;
      },
      maxSize: 100,
      size: 100,
    }),
    depsColumnHelper.accessor("latestVersion", {
      cell: ({ row }) => renderPreview(row),
      maxSize: 100,
      size: 100,
    }),
  ];

  const dataWithSubRows = React.useMemo(() => {
    return dependencies.map((dependency) => {
      const latestVersion = dependency.currentVersion.rawVersion.startsWith("^")
        ? `^${dependency.upgrade?.version}`
        : dependency.upgrade?.version;

      //const changelog = dependency.upgrade?.versions
      //  .map((version) => version.changelog)
      //  .filter(Boolean);

      const versions =
        dependency.upgrade?.versions.map((version) => {
          const v = dependency.currentVersion.rawVersion.startsWith("^")
            ? `^${version.rawVersion}`
            : version.rawVersion;

          return {
            version: v,
            id: version.id,
            releasedAt: version.releasedAt,
          };
        }) ?? [];

      const selectedVersionId = versions.find(
        (v) => v.version === latestVersion
      )?.id;

      return {
        id: dependency.package.id,
        name: dependency.package?.name ?? "",
        rawVersion: dependency.currentVersion.rawVersion,
        manifestPackageId: dependency.id,
        latestVersion,
        selectedVersion: latestVersion,
        selectedVersionId,
        updateType: dependency.upgrade?.type,
        versions,
        // FIXME
        changelog: [],
        state: dependency.state,
        pullRequest: dependency.pullRequest ?? undefined,
        description: dependency.package.description ?? "",
        scoreTotal: dependency.package.score.total,
        dataSource: dependency.package.dataSource,
        releasedAt: dependency.currentVersion.releasedAt,
      };
    });
  }, [dependencies]);

  const table = useReactTable({
    data: dataWithSubRows,
    columns: depsColumns,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      expanded,
    },
    // We can't reply on the default index-based id
    // because the amount of rows in the dependencies table can change (eg when filtering)
    getRowId: (row, _relativeIndex, parent) => {
      return parent
        ? [parent.id, row.manifestPackageId].join(".")
        : row.manifestPackageId;
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false,
  });

  return (
    <table className={wrapperStyles}>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            onClick={() => handlePackageToggle(row.index)}
            className={
              row.getIsSelected() ? trStyles.Selected : trStyles.Regular
            }
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className={tdStyles}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
