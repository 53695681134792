import { RadialBar, RadialBarChart } from "recharts";

import { vars } from "@/styles/theme.css";

import { labelStyles, wrapperStyles } from "./ScoreRadialBar.css";

type ScoreRadialBarProps = {
  value: number;
};

export const ScoreRadialBar = ({ value }: ScoreRadialBarProps) => {
  const dataToUse = [
    {
      name: "base",
      uv: 10,
      fill: "transparent",
    },
    {
      name: value.toString(),
      uv: value,
      fill: convertPackageScoreToColor(value),
    },
  ];

  return (
    <div className={wrapperStyles}>
      <RadialBarChart
        innerRadius="40%"
        outerRadius="100%"
        barSize={12}
        data={dataToUse}
        width={26}
        height={26}
        startAngle={0}
        endAngle={-360}
      >
        <RadialBar dataKey="uv" />
      </RadialBarChart>
      <span className={labelStyles}>{value}</span>
    </div>
  );
};

export const convertPackageScoreToColor = (score: number) => {
  if (score > 8) {
    return vars.color.primary9;
  }

  if (score > 6) {
    return vars.color.warning9;
  }

  return vars.color.error9;
};
