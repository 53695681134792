import { Crisp } from "crisp-sdk-web";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import { Icon } from "@/components/Icon/Icon";
import { NotificationBadge } from "@/components/NotificationBadge/NotificationBadge";
import { User, Workspace } from "@/generated/graphql";

import { Profile } from "./Profile/Profile";
import {
  itemContent,
  itemStyles,
  itemsStyles,
  mainItemsStyles,
  wrapperStyles,
} from "./Sidebar.css";

type ItemProps = {
  href: string;
  text: string;
  icon: React.ReactNode;
  count?: number;
};

const Item = ({ href, text, icon, count }: ItemProps) => {
  return (
    <NavLink to={href} className={itemStyles}>
      <div className={itemContent}>
        {icon}
        {text}
      </div>
      {count ? <NotificationBadge count={count} /> : null}
    </NavLink>
  );
};

type SidebarProps = {
  user?: User;
  workspace?: Workspace;
  workspaces?: Workspace[];
  notifications?: number;
};

export const Sidebar = ({
  user,
  workspaces,
  workspace,
  notifications,
}: SidebarProps) => {
  const handleOpenChat = () => {
    Crisp.chat.open();
  };
  const flagEnabled = useFeatureFlagEnabled("is-admin");

  return (
    <div className={wrapperStyles}>
      <div className={mainItemsStyles}>
        <Profile
          profileImg={user?.profileImgHref}
          name={user?.name ?? ""}
          email={user?.email ?? ""}
          workspaces={workspaces}
          workspace={workspace}
        />
        <div className={itemsStyles}>
          <Item href="/dashboard" text="Dashboard" icon={<Icon.BarChart />} />
          <Item
            href="/repositories"
            text="Repositories"
            count={notifications}
            icon={<Icon.Repositories />}
          />
          <Item href="/licenses" text="Licenses" icon={<Icon.FileShield />} />
          <Item href="/packages" text="Packages" icon={<Icon.Package />} />
          {flagEnabled && (
            <Item href="/alerts" text="Alerts" icon={<Icon.Announcement />} />
          )}
          {flagEnabled && (
            <Item href="/logs" text="Logs" icon={<Icon.AlignLeft />} />
          )}
        </div>
      </div>
      <div className={itemsStyles}>
        <Item href="/settings" text="Settings" icon={<Icon.Settings />} />
        <Item href="/docs" text="Docs" icon={<Icon.Docs />} />
        <Link to="#" className={itemStyles} onClick={handleOpenChat}>
          <div className={itemContent}>
            <Icon.Message />
            Help & Support
          </div>
        </Link>
      </div>
    </div>
  );
};
