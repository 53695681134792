import classNames from "classnames";

import { Card } from "@/components/Card/Card";
import { Icon } from "@/components/Icon/Icon";
import { LineChart, LineChartProps } from "@/components/LineChart/LineChart";

import { Tooltip } from "../Tooltip/Tooltip";
import {
  arrowColorStyles,
  arrowStyles,
  changeValueStyles,
  chartStyles,
  dataWrapperStyles,
  mainValueStyles,
} from "./ChartCard.css";

type Change = {
  value: string;
  direction: "up" | "down";
  color: "green" | "red";
};

type CardChartProps = {
  title: string;
  value?: string | number;
  change?: Change;
  chartData: LineChartProps;
  dimmed?: boolean;
};

export const ChartCard = ({
  title,
  value,
  change,
  chartData,
  dimmed,
}: CardChartProps) => {
  const isChangeEmpty =
    !change || change.value === "0" || change.value === "0%";

  return (
    <Card title={title} dimmed={dimmed}>
      <div className={dataWrapperStyles}>
        <div>
          {!isChangeEmpty ? (
            <>
              <div className={mainValueStyles}>{value}</div>
              <Tooltip content="Compared to the previous period">
                <div className={changeValueStyles}>
                  <Icon.ArrowRight
                    className={classNames(
                      change.direction === "up"
                        ? arrowStyles.Up
                        : arrowStyles.Down,
                      change.color === "green"
                        ? arrowColorStyles.Green
                        : arrowColorStyles.Red
                    )}
                  />
                  {change?.value}
                </div>
              </Tooltip>
            </>
          ) : (
            <div className={changeValueStyles}>No change</div>
          )}
        </div>
        <div className={chartStyles}>
          <LineChart {...chartData} />
        </div>
      </div>
    </Card>
  );
};
