export const ArrowRightSVG = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.22916 7.99993H12.2708M12.2708 7.99993L7.74999 3.67566M12.2708 7.99993L7.74999 12.3242"
      stroke="currentColor"
      strokeWidth="1.40909"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
