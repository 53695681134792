import { Separator } from "@radix-ui/react-dropdown-menu";
import * as Tabs from "@radix-ui/react-tabs";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import {
  tabsContentStyles,
  tabsListStyles,
  tabsTriggerStyles,
} from "@/components/Tabs/Tabs.css";
import { AppLayout } from "@/layouts/AppLayout/AppLayout";

import { General } from "./General/General";
import { Members } from "./Members/Members";
import { tabsRootSettingsStyles } from "./Settings.css";

enum Tab {
  General = "general",
  Members = "members",
}

export const Settings = () => {
  const { tab = Tab.General } = useParams();
  const navigate = useNavigate();

  const handleTabsChange = (value: string) => {
    navigate(`/settings/${value}`, {
      replace: true,
    });
  };

  return (
    <>
      <Helmet>
        <title>Settings - DepsHub</title>
      </Helmet>

      <AppLayout.Header
        title="Workspace settings"
        description="All the changes are saved automatically."
      />
      <Separator />
      <Tabs.Root
        className={tabsRootSettingsStyles}
        value={tab}
        onValueChange={handleTabsChange}
      >
        <Tabs.List className={tabsListStyles}>
          <Tabs.Trigger className={tabsTriggerStyles} value={Tab.General}>
            General
          </Tabs.Trigger>
          <Tabs.Trigger className={tabsTriggerStyles} value={Tab.Members}>
            Members
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content className={tabsContentStyles} value={Tab.General}>
          <General />
        </Tabs.Content>
        <Tabs.Content className={tabsContentStyles} value={Tab.Members}>
          <Members />
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};
