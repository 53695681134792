import { Icon } from "@/components/Icon/Icon";
import { connectGithubRepository } from "@/utils";

import {
  connectButtonStyles,
  soonStyles,
  wrapperStyles,
} from "./ConnectRepositories.css";

type ConnectRepositoriesProps = {
  onNext: () => void;
};

export const ConnectRepositories = ({ onNext }: ConnectRepositoriesProps) => {
  const handleAddRepository = () => {
    connectGithubRepository();
    onNext();
  };

  return (
    <div className={wrapperStyles}>
      <button className={connectButtonStyles} onClick={handleAddRepository}>
        <Icon.Github />
        Add Github repository
      </button>
      <button className={connectButtonStyles} disabled>
        <Icon.Bitbucket />
        Add Bitbucket repository
        <span className={soonStyles}>Coming soon</span>
      </button>
      <button className={connectButtonStyles} disabled>
        <Icon.Gitlab />
        Add Gitlab repository
        <span className={soonStyles}>Coming soon</span>
      </button>
    </div>
  );
};
