import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Input } from "@/components/Input/Input";
import { Separator } from "@/components/Separator/Separator";
import {
  useRootQueryQuery,
  useUpdateWorkspaceSettingsMutation,
} from "@/generated/graphql";

import {
  categoryDescriptionStyles,
  categoryTitleStyles,
  leftWrapperStyles,
  rightWrapperStyles,
  settingsSavedLabel,
  settingsWrapperStyles,
} from "./General.css";

export const General = () => {
  const [{ data }] = useRootQueryQuery();
  const workspace = data?.workspace;
  const [workspaceName, setWorkspaceName] = useState("");
  const [, updateWorkspaceSettingsMutation] =
    useUpdateWorkspaceSettingsMutation();

  // This is a hack to reset the input value when we change the workspace
  const [inputKey, setInputKey] = useState(0);

  useEffect(() => {
    if (workspace) {
      setWorkspaceName(workspace.name);
      setInputKey(Math.random());
    }
  }, [workspace]);

  const handleInputChange = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value === workspaceName || !value) {
        return;
      }

      updateWorkspaceSettingsMutation({
        settings: {
          name: e.target.value,
        },
      });
    },
    1000
  );

  return (
    <>
      <div className={settingsWrapperStyles}>
        <div className={leftWrapperStyles}>
          <div className={categoryTitleStyles}>Workspace name</div>
          <div className={categoryDescriptionStyles}>
            This is how your workspace will be displayed in your account.
          </div>
        </div>
        <div className={rightWrapperStyles}>
          <Input
            key={inputKey}
            defaultValue={workspaceName}
            placeholder="Enter workspace name"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <Separator marginButtom="12px" />
      <div className={settingsSavedLabel}>
        Your settings are saved automatically.
      </div>
    </>
  );
};
